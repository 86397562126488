import { Alert } from "@mui/material"
import { RedirectionSideEffect, useRecordContext } from "react-admin"
import {
  Create,
  Datagrid,
  DateField,
  BooleanField,
  Edit,
  Tab,
  TabbedShowLayout,
  ReferenceManyField,
  List,
  Show,
  NumberField,
  TextField,
  ShowButton,
  EditButton,
} from "react-admin"

import { PropertyRefField, PropertyRefInput } from "../../real_estate/properties"
import { AddProductButton } from "./AddProductButton"
import { CategoryForm } from "./CategoryForm"

const listFilters = [<PropertyRefInput source="property_id" alwaysOn />]

export const CategoryList = () => (
  <List filters={listFilters} exporter={false}>
    <Datagrid bulkActionButtons={false} rowClick="show">
      <PropertyRefField source="property_id" />
      <TextField source="title" />
      <NumberField source="vat_rate" />
      <NumberField source="discount_amount" />
      <NumberField source="discount_percentage" />
      <TextField source="discount_title" />
      <BooleanField source="auto_bill_products" />
      <TextField source="billable_amount_from" />
      <DateField source="created_at" showTime />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
)

const ProductCategoryTitle = () => {
  const record = useRecordContext()

  return <span>{record ? record.title : ""} [Product Category]</span>
}

export const CategoryShow = () => (
  <Show title={<ProductCategoryTitle />}>
    <TabbedShowLayout>
      <Tab label="Category Details">
        <PropertyRefField source="property_id" />
        {/* <TextField source="category_code" /> */}
        <TextField source="title" />
        {/* <TextField source="title_de" /> */}
        <TextField source="title_short" />
        {/* <TextField source="title_short_de" /> */}
        <TextField source="description" />
        {/* <TextField source="description_de" /> */}
        <BooleanField source="details_hidden" />
        <NumberField source="vat_rate" />
        <BooleanField source="auto_bill_products" />
        <TextField source="billable_amount_from" />
      </Tab>

      <Tab label="Discount">
        <NumberField source="discount_amount" />
        <NumberField source="discount_percentage" />
        <TextField source="discount_title" />
        {/* <TextField source="discount_title_de" /> */}
      </Tab>

      <Tab label="Products" path="products">
        <AddProductButton />
        <ReferenceManyField
          reference="products"
          target="category_id"
          sort={{ field: "created_at", order: "DESC" }}
          label={false}
        >
          <Datagrid
            bulkActionButtons={false}
            rowClick="show"
            empty={<Alert severity="info">This category has no products yet.</Alert>}
          >
            <PropertyRefField source="property_id" />
            <BooleanField source="is_published" />
            <TextField source="product_code" />
            <TextField source="title" />
            <TextField source="pricing_model" />
            <TextField source="unit" />
            <DateField source="created_at" showTime />
            <ShowButton />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>

      <Tab label="Meta">
        <TextField source="id" />
        <DateField source="created_at" showTime />
        <DateField source="updated_at" showTime />
        <TextField source="created_by" />
        <NumberField source="version" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export const CategoryCreate = (props: any) => (
  <Create {...props} mutationMode="pessimistic" redirect="show">
    <CategoryForm isExisting={false} />
  </Create>
)

export const CategoryEdit = (props: any) => (
  <Edit {...props} mutationMode="pessimistic" redirect="show">
    <CategoryForm isExisting />
  </Edit>
)
