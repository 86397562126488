import { Grid, Stack, Typography } from "@mui/material"
import React from "react"
import {
  BooleanInput,
  PasswordInput,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SimpleFormProps,
  TextInput,
  Toolbar,
} from "react-admin"

import { SelectLanguageInput, SelectTimezoneInput } from "../../../inputs"

type Props = Omit<Omit<SimpleFormProps, "children">, "render"> & {
  isExisting: boolean
}

const FormToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
)

export const UserForm: React.FC<Props> = ({ isExisting = false, defaultValues }) => {
  // const { isValid } = useFormState()
  // const create_account = useWatch({ name: "create_account" })

  return (
    <SimpleForm defaultValues={defaultValues}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Typography variant="h6" gutterBottom>
            Identity
          </Typography>
          <Stack direction="row" spacing={2} alignItems="baseline">
            <TextInput fullWidth source="first_name" />
            <TextInput fullWidth source="last_name" />
          </Stack>

          <TextInput fullWidth source="email" disabled={isExisting} />

          <Stack direction="row" spacing={2} alignItems="baseline">
            <SelectInput
              fullWidth
              source="salutation"
              choices={[
                { id: "mr", name: "Herr" },
                { id: "ms", name: "Frau" },
                { id: "other", name: "Keine Angabe" },
              ]}
            />
            <TextInput fullWidth source="phone" />
          </Stack>

          <Typography variant="h6" gutterBottom>
            Int
          </Typography>
          <Stack direction="row" spacing={2} alignItems="baseline">
            <SelectLanguageInput fullWidth source="language" />
            <SelectTimezoneInput fullWidth source="timezone" />
          </Stack>

          <Typography variant="h6" gutterBottom>
            {isExisting ? "Change" : "Set"} Password
          </Typography>
          <Stack direction="row" spacing={2} alignItems="baseline">
            <PasswordInput source="password" resource="customers" fullWidth />
            <PasswordInput source="confirm_password" resource="customers" fullWidth />
          </Stack>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="h6" gutterBottom>
            Access
          </Typography>
          {!isExisting && <BooleanInput fullWidth source="create_account" />}

          <SelectArrayInput
            fullWidth
            label="Roles"
            source="roles"
            choices={[
              { id: "admin", name: "Admin" },
              { id: "sales", name: "Sales" },
              { id: "staff", name: "Staff" },
              // { id: "user", name: "User" },
            ]}
            helperText="Select only for MYNE employees!"
          />

          {/*
          {isExisting && (
            <div>
              <TextInput
                fullWidth
                source="account_id"
                label="Account ID"
                helperText="Identity Platform"
              />
            </div>
          )}
          */}

          <BooleanInput fullWidth source="is_disabled" />
        </Grid>
      </Grid>
    </SimpleForm>
  )
}
