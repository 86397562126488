import { EditButton, RedirectionSideEffect, ShowButton } from "react-admin"
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  List,
  Show,
  TabbedShowLayout,
  NumberField,
  Tab,
  TextField,
} from "react-admin"

import { BillGroupRefField, BillGroupRefInput } from "../bill_groups"
import { MoneyField } from "../bills/MoneyField"
import { ProductRefField, ProductRefInput } from "../products"
import { BillLineItemForm } from "./BillLineItemForm"

const listFilters = [
  <BillGroupRefInput source="bill_group_id" />,
  <ProductRefInput source="product_id" />,
]

export const BillLineItemList = () => (
  <List filters={listFilters} exporter={false}>
    <Datagrid rowClick="show">
      <BillGroupRefField source="group_id" />
      <ProductRefField source="product_id" />
      <TextField source="product_code" />
      <TextField source="title" />
      <NumberField source="quantity" />
      <TextField source="pricing_model" />
      <TextField source="unit" />
      <NumberField source="unit_count" />
      <MoneyField source="price" />
      <MoneyField source="total" />
      <DateField source="created_at" showTime />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
)

export const BillLineItemShow = () => (
  <Show>
    <TabbedShowLayout>
      <Tab label="Line Item Details">
        <BillGroupRefField source="group_id" />
        <ProductRefField source="product_id" />
        <TextField source="product_code" />
        <TextField source="title" />
        <TextField source="description" />
        <NumberField source="quantity" />
        <TextField source="pricing_model" />
        <TextField source="unit" />
        <NumberField source="unit_count" />
        <MoneyField source="price" />
        <MoneyField source="total" />
      </Tab>

      <Tab label="Meta">
        <TextField source="id" />
        <DateField source="created_at" showTime />
        <DateField source="updated_at" showTime />
        <TextField source="created_by" />
        <NumberField source="version" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

const redirect: RedirectionSideEffect = (resource, id, data) =>
  data ? `bill_groups/${data.group_id}/show/line_items` : "show"

export const BillLineItemCreate = () => (
  <Create redirect={redirect}>
    <BillLineItemForm isExisting={false} />
  </Create>
)

export const BillLineItemEdit = () => (
  <Edit mutationMode="pessimistic" redirect={redirect}>
    <BillLineItemForm isExisting />
  </Edit>
)
