import {
  Create,
  NumberField,
  Datagrid,
  DateField,
  Edit,
  BooleanField,
  List,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  BooleanInput,
  ShowButton,
  EditButton,
  RedirectionSideEffect,
} from "react-admin"

import { OwnerRefInput } from "../../real_estate/owners"
import { PropertyRefField, PropertyRefInput } from "../../real_estate/properties"
import { PropertyProfileForm } from "./PropertyProfileForm"

const propertyProfileFilters = [
  <PropertyRefInput source="property_id" />,
  <OwnerRefInput source="customer_id" />,
  // <DateInput source="valid_from" />
  <BooleanInput source="is_disabled" />,
]

export const PropertyProfileList = () => (
  <List filters={propertyProfileFilters} exporter={false}>
    <Datagrid bulkActionButtons={false} rowClick="show">
      <PropertyRefField source="property_id" />
      <DateField source="created_at" showTime />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
)

export const PropertyProfileShow = () => (
  <Show>
    <TabbedShowLayout spacing={2}>
      <Tab label="Profile Details">
        <PropertyRefField source="property_id" />
        <TextField source="title" />
        <TextField source="checkinout_description" />
        <NumberField source="max_adults_count" />
        <NumberField source="max_children_count" />
        <NumberField source="max_infants_count" />
        <DateField source="valid_from" />
        <DateField source="valid_until" />
        <TextField source="billing_items" />
        <BooleanField source="is_disabled" />
        <TextField source="internal_notes" />
      </Tab>

      <Tab label="Meta">
        <TextField source="id" />
        <DateField source="created_at" showTime />
        <DateField source="updated_at" showTime />
        <TextField source="created_by" />
        <NumberField source="version" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

const redirect: RedirectionSideEffect = (resource, id, data) =>
  data ? `properties/${data.property_id}/show/profiles` : "show"

export const PropertyProfileCreate = () => (
  <Create redirect={redirect}>
    <PropertyProfileForm isExisting={false} />
  </Create>
)

export const PropertyProfileEdit = () => (
  <Edit mutationMode="pessimistic" redirect={redirect}>
    <PropertyProfileForm isExisting />
  </Edit>
)
