import React from "react"
import { FunctionField, ReferenceField, ReferenceFieldProps, TextField } from "react-admin"

import { ICalendarEntry } from "@/types/ICalendarEntry"

interface Props {
  source?: string
}

export const CalendarEntryRefField: React.FC<
  Props & Omit<Omit<ReferenceFieldProps, "source">, "reference" | "children">
> = (props) => (
  <ReferenceField
    label="Event"
    reference="calendar_entries"
    link="show"
    // {...props}
    source={props.source ?? "calendar_entry_id"}
    sortBy="created_at"
    sortByOrder="ASC"
  >
    {/* <TextField source="title" /> */}
    <FunctionField
      label="Name"
      render={(record: ICalendarEntry) =>
        `${record.start_date}-${record.end_date} (${record.title})`
      }
    />
  </ReferenceField>
)
