import React from "react"
import { InputProps, ReferenceInput, SelectInput, ReferenceInputProps } from "react-admin"

interface Props extends Omit<InputProps, "source"> {
  source?: string
}

const OwnershipRefInput: React.FC<
  Props & Omit<Omit<ReferenceInputProps, "source">, "reference" | "children">
> = (props) => (
  <ReferenceInput
    source="ownership_id"
    reference="ownerships"
    perPage={100}
    // sort={{
    //   field: "created_at",
    //   order: "ASC",
    // }}
  >
    <SelectInput optionText="id" {...props} label="Ownership" />
  </ReferenceInput>
)

OwnershipRefInput.defaultProps = {
  source: "ownership_id",
}

export { OwnershipRefInput }
