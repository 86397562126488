import { StyleSheet, Text, View } from "@react-pdf/renderer"

import { LineItemDto, PricingModelEnum } from "../../types/Bill"
import { getFormattedAmount } from "./utils"

const styles = StyleSheet.create({
  container: {
    // backgroundColor: "blue",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginBottom: "1mm",
  },

  itemTitle: {
    // backgroundColor: "#fa2",
    alignSelf: "flex-start",
    flexGrow: 2,
    paddingBottom: "1mm",
  },
  itemQuantities: {
    // backgroundColor: "lightblue",
    width: "30mm",
    alignItems: "flex-end",
    paddingBottom: "1mm",
  },
  itemUnitPrice: {
    // backgroundColor: "#ff2",
    width: "30mm",
    alignItems: "flex-end",
    paddingBottom: "1mm",
  },
  itemTotal: {
    // backgroundColor: "#9df",
    width: "30mm",
    alignItems: "flex-end",
    paddingBottom: "1mm",
  },

  textBold: {
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
  },
})

export const LineItemHeading = () => {
  return (
    <View style={styles.container}>
      <View style={[styles.itemTitle, styles.textBold]}>
        <Text>Beschreibung</Text>
      </View>
      <View style={[styles.itemQuantities, styles.textBold]}>
        <Text>Menge</Text>
      </View>
      <View style={[styles.itemUnitPrice, styles.textBold]}>
        <Text>Preis</Text>
      </View>
      <View style={[styles.itemTotal, styles.textBold]}>
        <Text>Betrag</Text>
      </View>
    </View>
  )
}

interface LineItemProps {
  item: LineItemDto
}

export const LineItem = ({ item }: LineItemProps) => {
  const totalAmountFormatted = getFormattedAmount(item.total_amount, item.total_scale)
  const priceFormatted = getFormattedAmount(item.price_amount, item.price_scale)
  let description: string | undefined = undefined

  if (item.pricing_model === PricingModelEnum.per_unit) {
    description = item.unit
  } else if (item.pricing_model === PricingModelEnum.flat_fee) {
    description = `flat fee`
  }

  return (
    <View style={styles.container}>
      <View style={styles.itemTitle}>
        <Text>{item.title}</Text>
      </View>
      <View style={styles.itemQuantities}>
        <Text>{item.unit_count ?? 1}</Text>
      </View>
      <View style={styles.itemUnitPrice}>
        <Text>{priceFormatted}</Text>
      </View>
      <View style={styles.itemTotal}>
        <Text>{totalAmountFormatted}</Text>
      </View>
    </View>
  )
}
