import { ReferenceInputProps, ReferenceInput, SelectInput, AutocompleteInput } from "react-admin"

interface Props extends Omit<ReferenceInputProps, "source"> {
  source?: string
}

const UserRefInput = (props: Props) => (
  <ReferenceInput
    source="user_id"
    reference="users"
    sort={{ field: "created_at", order: "DESC" }}
    // enableGetChoices={({ q }) => !!q && q.length >= 2}
    // filterToQuery={(searchText: string) => ({ search: searchText })}
    perPage={200}
  >
    <SelectInput fullWidth={props.fullWidth} optionText="full_name" {...props} />
    {/* <AutocompleteInput fullWidth={props.fullWidth} optionText="full_name" /> */}
  </ReferenceInput>
)

UserRefInput.defaultProps = {
  source: "user_id",
}

export { UserRefInput }
