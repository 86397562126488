const firebaseConfig = {
  config: {
    apiKey: process.env.REACT_APP_GIP_API_KEY,
    authDomain: process.env.REACT_APP_GIP_AUTH_DOMAIN,
    databaseURL: undefined,
    projectId: process.env.REACT_APP_GIP_PROJECT_ID,
    storageBucket: undefined,
    messagingSenderId: undefined,
  },
}

export default firebaseConfig
