import { InputProps, SelectInput } from "react-admin"

// import { EntryTypeChoices } from "../constants"
export const EntryTypeChoices = [
  { id: "booking", name: "booking" },
  { id: "season", name: "season" },
  { id: "special_date", name: "special_date" },
  { id: "main_holiday_period", name: "main_holiday_period" },
  { id: "other", name: "other" },
]

export const SelectEntryTypeInput: React.FC<InputProps> = (props) => (
  <SelectInput {...props} choices={EntryTypeChoices} />
)
