import { Document } from "@react-pdf/renderer"
import { Loading } from "react-admin"

import { BillDto } from "../types/Bill"
import { ICalendarEntry } from "../types/ICalendarEntry"
import { IProperty } from "../types/IProperty"
import { BookingBillPdf } from "./BookingBillPdf/BookingBillPdf"

interface BillProps {
  bill?: BillDto
  booking?: ICalendarEntry
  property?: IProperty
  title?: string
}

export const BookingBillPdfDoc = (props: BillProps) => {
  const title = `${props.bill?.title_text} ${props.bill?.unique_number}`

  if (!props.bill) return <Loading />

  return (
    <Document title={title}>
      <BookingBillPdf {...props} />
    </Document>
  )
}
