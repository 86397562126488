import React from "react"
import {
  BooleanInput,
  SimpleFormProps,
  TextInput,
  Labeled,
  SimpleForm,
  required,
  FormDataConsumer,
} from "react-admin"

import { PropertyRefField, PropertyRefInput } from "../../real_estate/properties"
import { CategoryRefInput } from "../product_categories"
import { ProductPricingModelSelectInput } from "./inputs/ProductPricingModelSelectInput"
import { ProductUnitSelectInput } from "./inputs/ProductUnitSelectInput"

type Props = Partial<SimpleFormProps> & {
  isExisting: boolean
}

export const ProductForm: React.FC<Props> = ({ isExisting = false, ...rest }) => (
  <SimpleForm {...rest}>
    {isExisting ? (
      <Labeled source="property_id" fullWidth>
        <PropertyRefField source="property_id" />
      </Labeled>
    ) : (
      <PropertyRefInput source="property_id" validate={[required()]} />
    )}

    <FormDataConsumer>
      {({ formData, ...rest }) => {
        // console.log("[FormDataConsumer] formData", formData)

        return (
          <CategoryRefInput
            source="category_id"
            validate={[required()]}
            filter={!!formData.property_id ? { property_id: formData.property_id } : undefined}
            {...rest}
          />
        )
      }}
    </FormDataConsumer>

    <BooleanInput
      source="is_published"
      helperText="Only published products will be included in billing/invoices"
    />

    {/* <TextInput source="name" helperText="Internal name" /> */}
    <TextInput
      source="product_code"
      validate={[required()]}
      helperText="Can only contain lowercase a-z, underscores or hyphens."
    />
    {/* <TextInput source="sku" /> */}
    <TextInput source="title" validate={[required()]} helperText="Displayed to customers." />
    {/* <TextInput source="title_de" /> */}
    {/* <TextInput source="subtitle" /> */}
    {/* <TextInput source="label" /> */}
    {/* <TextInput multiline rows={3} source="description" /> */}

    {/* <NumberInput
      source="vat_rate"
      label="VAT Rate"
      validate={[number(), minValue(0), maxValue(100)]}
      helperText="As integer only (eg. 7 or 19). If left empty net or gross price will not be calculated automatically."
    /> */}

    {/* // ---------------------------------- */}

    {/* <NumberInput source="vat_rate" validate={[number(), minValue(0), maxValue(100)]} />
    <NumberInput source="price_net" />
    <NumberInput source="price_gross" /> */}

    {/* // ---------------------------------- */}

    <FormDataConsumer>
      {({ formData, ...rest }) => (
        <>
          <ProductPricingModelSelectInput source="pricing_model" validate={[required()]} />

          {formData.pricing_model === "per_unit" && (
            <>
              <ProductUnitSelectInput source="unit" />
              {formData.unit === "guest" && <BooleanInput source="guest_count_includes_adults" />}
              {formData.unit === "guest" && <BooleanInput source="guest_count_includes_children" />}
              {formData.unit === "guest" && <BooleanInput source="guest_count_includes_infants" />}
            </>
          )}
        </>
      )}
    </FormDataConsumer>
  </SimpleForm>
)
