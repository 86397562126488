import { AuthProvider, fetchUtils } from "react-admin"

/**
 * HTTP Client Factory
 *
 * @returns
 */
export const makeHttpClient =
  (authProvider: AuthProvider) =>
  async (url: string, options: fetchUtils.Options = {}) => {
    const requestHeaders = (options.headers ||
      new Headers({
        Accept: "application/json",
      })) as Headers

    const token = await authProvider.getJWTToken()

    if (token) {
      requestHeaders.set("Authorization", `Bearer ${token}`)
    }

    return fetchUtils.fetchJson(url, { ...options, headers: requestHeaders })
  }
