import firebase from "firebase/compat/app"
import "firebase/compat/auth"

import { AuthOptions } from "./AuthOptions"
import { FireApp, IFirebaseWrapper } from "./IFirebaseWrapper"

function ObtainFirebaseApp(firebaseConfig: {}, options: AuthOptions): firebase.app.App {
  if (options.app) {
    return options.app
  }

  const isInitialized = !!firebase.apps.length

  if (isInitialized) {
    return firebase.app()
  } else {
    return firebase.initializeApp(firebaseConfig)
  }
}

export class FirebaseWrapper implements IFirebaseWrapper {
  private app: FireApp = null as any
  public options: AuthOptions = {}

  public GetApp(): FireApp {
    return this.app
  }

  public init(firebaseConfig: {}, options?: AuthOptions): void {
    const optionsSafe = options || {}
    this.options = optionsSafe
    this.app = ObtainFirebaseApp(firebaseConfig, optionsSafe)
  }

  public auth() {
    return this.app.auth() as any
  }

  // public storage() {
  //   return this.app.storage()
  // }

  public async GetUserLogin(): Promise<firebase.User> {
    return new Promise((resolve, reject) => {
      this.app.auth().onAuthStateChanged((user) => {
        if (user) {
          resolve(user)
        } else {
          reject("getUserLogin() no user logged in")
        }
      })
    })
  }

  public OnUserLogout(callBack: (u: firebase.User | null) => any) {
    this.app.auth().onAuthStateChanged((user) => {
      const isLoggedOut = !user

      console.log("FirebaseWrapper.OnUserLogout", { user, isLoggedOut })

      if (isLoggedOut) {
        callBack(user)
      }
    })
  }
}
