import React from "react"
import { InputProps, ReferenceInput, SelectInput, ReferenceInputProps } from "react-admin"

interface Props extends Omit<InputProps, "source"> {
  source?: string
}

const PropertyRefInput: React.FC<
  Props & Omit<Omit<ReferenceInputProps, "source">, "reference" | "children">
> = (props) => (
  <ReferenceInput
    source="property_id"
    reference="properties"
    sort={{
      field: "short_id",
      order: "ASC",
    }}
    perPage={50}
  >
    <SelectInput optionText={(record) => `[${record.short_id}] ${record.name}`} {...props} />
  </ReferenceInput>
)

PropertyRefInput.defaultProps = {
  source: "property_id",
}

export { PropertyRefInput }
