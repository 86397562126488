import { Alert } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import jsonExport from "jsonexport/dist"
import { get, pick } from "lodash"
import {
  BooleanField,
  Datagrid,
  DateField,
  List,
  NumberField,
  TextField,
  DateInput,
  useRecordContext,
  Exporter,
  downloadCSV,
  SimpleShowLayout,
  ReferenceManyField,
  ShowButton,
} from "react-admin"
import { MoneyField } from "resources/billing/bills/MoneyField"

import { UserRefInput } from "../../iam/users"
import { OwnerRefField, OwnerRefInput } from "../../real_estate/owners"
import { CalendarRefField, CalendarRefInput } from "../calendars"
import { SelectEntryStatusInput } from "../entry_statuses/inputs/SelectEntryStatusInput"
import { QuotaRefInput } from "../quotas"
import { CalendarEntry } from "../types"
import EntryStatusField from "./fields/EntryStatusField"
import { SelectEntryTypeInput } from "./inputs/SelectEntryTypeInput"
import { rowStyle } from "./rowStyle"

const exportedEntryFields = [
  "id",
  "calendar_id",
  "customer_id",
  "title",
  "start_date",
  "end_date",
  "adults_count",
  "children_count",
  "infants_count",
  "has_pets",
  "local_tourism_tax_total_count",
  "current_status",
  "internal_notes",
  "created_at",
]

const listExporter: Exporter = (records, fetchRelatedRecords, dataProvider) => {
  const entriesForExport = records.map((record: CalendarEntry) => pick(record, exportedEntryFields))

  Promise.all([
    fetchRelatedRecords(entriesForExport, "calendar_id", "calendars"),
    fetchRelatedRecords(entriesForExport, "customer_id", "customers"),
  ]).then(([calendars, customers]) => {
    const data = entriesForExport.map((record: CalendarEntry) => ({
      ...record,
      calendar_title: get(calendars[record.calendar_id], "property_info.name"),
      owner_title: get(customers[record.customer_id], "display_name"),
    }))

    jsonExport(
      data,
      {
        headers: ["calendar_title", "owner_title", ...exportedEntryFields],
      },
      (err: any, csv: any) => {
        console.error(err)

        downloadCSV(csv, `calendar_entries_export`)
      },
    )
  })
}

const BookingPanel = () => {
  const record = useRecordContext<CalendarEntry>()

  if (!record) return null

  return (
    <SimpleShowLayout>
      <ReferenceManyField
        reference="calendar_entry_guests"
        source="id"
        target="calendar_entry_id"
        sort={{ field: "created_at", order: "DESC" }}
        label="All Guests"
      >
        <Datagrid
          rowClick="show"
          bulkActionButtons={false}
          empty={<Alert severity="info">No bills existing for this booking.</Alert>}
        >
          <TextField source="first_name" />
          <TextField source="last_name" />
          <TextField source="id_card_number" />
          <DateField source="birth_date" />
          <DateField source="attended_from" />
          <DateField source="attended_to" />

          <DateField source="created_at" showTime />
        </Datagrid>
      </ReferenceManyField>

      <ReferenceManyField
        reference="bills"
        source="id"
        target="booking_id"
        sort={{ field: "created_at", order: "DESC" }}
        label="All Saved Bills"
      >
        <Datagrid
          rowClick="show"
          bulkActionButtons={false}
          empty={<Alert severity="info">No bills existing for this booking.</Alert>}
        >
          <OwnerRefField source="customer_id" />
          <DateField source="check_in_day" />
          <DateField source="check_out_day" />
          <MoneyField source="subtotal" />
          <MoneyField source="vat" />
          <MoneyField source="total" />
          <MoneyField source="due" />
          <DateField source="created_at" showTime />
          <ShowButton />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  )
}

const useListStyles = makeStyles({
  headerRow: {
    borderLeftColor: "transparent",
    borderLeftWidth: 5,
    borderLeftStyle: "solid",
  },
  headerCell: {
    padding: "6px 8px 6px 8px",
  },
  rowCell: {
    padding: "6px 8px 6px 8px",
  },
  comment: {
    maxWidth: "18em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
})

const listFilters = [
  <CalendarRefInput label="Property Calendar" alwaysOn />,
  <DateInput source="start_date__gte" label="Starts at or after" alwaysOn />,
  <DateInput source="start_date__lte" label="Starts at or before" alwaysOn />,
  <SelectEntryTypeInput source="type" alwaysOn />,
  <SelectEntryStatusInput source="current_status" alwaysOn />,
  <OwnerRefInput alwaysOn />,
  <QuotaRefInput />,
  <UserRefInput />,
]

export const CalendarEntryList = () => {
  const classes = useListStyles()

  return (
    <List
      filters={listFilters}
      sort={{ field: "created_at", order: "DESC" }}
      exporter={listExporter}
    >
      <Datagrid
        bulkActionButtons={false}
        rowClick="show"
        expand={<BookingPanel />}
        isRowExpandable={(row) => row.type === "booking"}
        rowStyle={rowStyle()}
        sx={{
          "&.RaDatagridHeaderRow": classes.headerRow,
        }}
      >
        <CalendarRefField source="calendar_id" />
        <TextField source="type" />
        <OwnerRefField source="customer_id" label="Owner" />
        <TextField source="title" />
        <DateField source="start_date" />
        <DateField source="end_date" />
        <NumberField source="adults_count" />
        <NumberField source="children_count" />
        <NumberField source="infants_count" />
        <BooleanField source="has_pets" />
        <NumberField source="local_tourism_tax_total_count" />
        <EntryStatusField source="current_status" label="Reservation Status" />
        <TextField source="internal_notes" />
        <DateField source="created_at" showTime />
      </Datagrid>
    </List>
  )
}
