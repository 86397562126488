import { EUR } from "@dinero.js/currencies"
import { Dinero, allocate, dinero, isZero, trimScale, toFormat } from "dinero.js"
import { get } from "lodash"
import React from "react"
import { ReferenceField, ReferenceFieldProps, TextField } from "react-admin"
import { useRecordContext } from "react-admin"

const transformer = ({ amount, currency }: any) =>
  Intl.NumberFormat("de-DE", { style: "currency", currency: currency.code }).format(amount)

const MoneyField = (props: Partial<ReferenceFieldProps>) => {
  const { source } = props
  const record = useRecordContext(props)

  const amount = get(record, source + "_amount")
  const scale = get(record, source + "_scale")
  const formatted = amount ? toFormat(dinero({ amount, currency: EUR, scale }), transformer) : "N/A"

  return <div>{formatted}</div>
}

MoneyField.defaultProps = {
  source: "",
}

export { MoneyField }
