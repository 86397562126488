import React from "react"
import { SimpleFormProps, Labeled, required, SimpleForm, TextInput } from "react-admin"

import { CalendarEntryRefField, CalendarEntryRefInput } from "../../scheduling/calendar_entries"
import { BillLangCodeSelectInput } from "./BillLangCodeSelectInput"
import { DocumentTypeSelectInput } from "./DocumentTypeSelectInput"

type Props = Partial<SimpleFormProps> & {
  isExisting: boolean
}

export const BillCreateForm: React.FC<Props> = ({ isExisting = false, defaultValues }) => (
  <SimpleForm defaultValues={defaultValues}>
    {isExisting ? (
      <Labeled source="booking_id" fullWidth>
        <CalendarEntryRefField source="booking_id" />
      </Labeled>
    ) : (
      <CalendarEntryRefInput source="booking_id" validate={[required()]} />
    )}

    <DocumentTypeSelectInput
      source="document_type"
      helperText="Leave empty to automatically set document type"
    />

    <TextInput
      source="unique_number"
      helperText="Leave empty to automatically set unique number (does not happen directly at bill creation)"
    />

    <BillLangCodeSelectInput
      source="language_code"
      helperText="Leave empty to automatically set language (currently only german)"
    />

    <TextInput source="internal_notes" multiline rows={3} fullWidth />
  </SimpleForm>
)
