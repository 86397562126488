import Joi from "joi"
import { maxLength, minLength, TextInput, TextInputProps } from "react-admin"

const validateCountryCode = (value: string) => {
  // if (!value) {
  //   return "Must be a valid ISO country code (2 characters)"
  // }

  return undefined
}

export const CountryIsoInput: React.FC<TextInputProps> = (props) => (
  <TextInput {...props} validate={[minLength(2), maxLength(2), validateCountryCode]} />
)
