import { Stack, Typography } from "@mui/material"
import React from "react"
import {
  NumberInput,
  SimpleForm,
  BooleanInput,
  SimpleFormProps,
  TextInput,
  Labeled,
  required,
  FormDataConsumer,
  DateInput,
  TabbedForm,
  FormTab,
} from "react-admin"

import { PropertyRefField, PropertyRefInput } from "../../real_estate/properties"
import { BookingTypeSelectInput } from "../BookingTypeSelectInput"
import { GuestlistIdCardRequirementsSelectInput } from "./GuestlistIdCardRequirementsSelectInput"

type Props = Partial<SimpleFormProps> & {
  isExisting: boolean
}

export const PropertyProfileForm: React.FC<Props> = ({ isExisting = false }) => {
  return (
    <TabbedForm>
      <FormTab label="Profile Details">
        {isExisting ? (
          <Labeled source="property_id">
            <PropertyRefField source="property_id" />
          </Labeled>
        ) : (
          <PropertyRefInput source="property_id" validate={[required()]} />
        )}

        <BookingTypeSelectInput
          source="booking_type"
          disabled
          helperText="(This field is not yet ready for usage) Defines to which type of bookings this profile is applicable"
        />
      </FormTab>

      <FormTab label="Property Settings">
        <BooleanInput
          source="is_disabled"
          helperText="The property is disabled. Impacts bookings etc."
        />

        <DateInput source="bookable_from" helperText="Stays can only start on or after this date" />

        <TextInput
          fullWidth
          multiline
          rows={3}
          source="checkinout_description"
          label="Check-in/out description"
          helperText="Overwrites the default check-in/out info text. Displayed in-app (eg. on a booking's detail screen)"
        />
      </FormTab>

      <FormTab label="Guests Settings">
        <NumberInput
          source="max_adults_count"
          helperText="Max. number of adults that can stay at the property"
        />
        <NumberInput
          source="max_children_count"
          helperText="Max. number of children that can stay at the property"
        />
        <NumberInput
          source="max_infants_count"
          helperText="Max. number of infants that can stay at the property"
        />

        <NumberInput
          source="adult_start_age"
          helperText="Minimum age at which guests are counted as adults"
        />
        <NumberInput
          source="child_start_age"
          helperText="Minimum age at which guests are counted as children (all below are infants)"
        />

        <BooleanInput source="guestlist_required" />

        {/* <FormDataConsumer>
        {({ formData, ...rest }) => formData.guestlist_required && <></>}
      </FormDataConsumer> */}
        <BooleanInput source="guestlist_dob_required" label="Guestlist requires exact birthday" />

        <GuestlistIdCardRequirementsSelectInput
          source="guestlist_id_card_requirements"
          helperText="ID card number collection requirements"
        />
      </FormTab>
    </TabbedForm>
  )
}
