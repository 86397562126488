import { InputProps, SelectInput } from "react-admin"

// import { EntryTypeChoices } from "../constants"
export const EntryStatusChoices = [
  { id: "requested", name: "requested" },
  { id: "tentative", name: "tentative" },
  { id: "confirmed", name: "confirmed" },
  { id: "declined", name: "declined" },
  { id: "cancelled", name: "cancelled" },
]

export const SelectStatusInput: React.FC<InputProps> = (props) => (
  <SelectInput {...props} choices={EntryStatusChoices} />
)
