import React from "react"
import {
  NumberInput,
  BooleanInput,
  SimpleFormProps,
  Labeled,
  SimpleForm,
  required,
  TextInput,
  number,
  minValue,
  maxValue,
} from "react-admin"

import { PropertyRefField, PropertyRefInput } from "../../real_estate/properties"
import { BillableAmountFromSelectInput } from "./inputs/BillableAmountFromSelectInput"

type Props = Partial<SimpleFormProps> & {
  isExisting: boolean
}

export const CategoryForm: React.FC<Props> = ({ isExisting = false, ...rest }) => (
  <SimpleForm {...rest}>
    {isExisting ? (
      <Labeled source="property_id" fullWidth>
        <PropertyRefField source="property_id" />
      </Labeled>
    ) : (
      <PropertyRefInput source="property_id" validate={[required()]} />
    )}

    {/* <TextInput source="name" helperText="Internal name" validate={[required()]} /> */}

    {/* <TextInput source="category_code" /> */}

    <TextInput source="title" validate={[required()]} helperText="Shown to the customer" />
    {/* <TextInput source="title_de" /> */}
    <TextInput
      source="title_short"
      label="Short Title"
      helperText="Short version of the title. Shown to the customer"
    />
    {/* <TextInput source="title_short_de" /> */}
    <TextInput multiline rows={3} source="description" helperText="Shown to the customer" />
    {/* <TextInput multiline rows={3} source="description_de" /> */}

    <BooleanInput
      source="details_hidden"
      helperText="When enabled, single line items will not be shown to customers on the bill."
    />

    <NumberInput
      source="vat_rate"
      label="VAT Rate"
      validate={[number(), minValue(0), maxValue(100)]}
      helperText="As integer only (eg. 7 or 19). For Net = Gross leave empty."
    />

    <NumberInput
      source="discount_amount"
      helperText="A fixed amount will be discounted from all items in this category. Only specify discount amount OR percentage."
    />
    <NumberInput
      source="discount_percentage"
      helperText="A percentage will be discounted from all items in this category. Only specify discount amount OR percentage."
    />
    <TextInput
      source="discount_title"
      helperText="Will be shown to describe the discount to customers."
    />
    {/* <TextInput source="discount_title_de" /> */}

    <BooleanInput
      source="auto_bill_products"
      defaultValue={true}
      label="Automatically bill products"
      helperText="Include all products of this category in the bill by default."
    />

    <BillableAmountFromSelectInput
      source="billable_amount_from"
      helperText="Leave empty by default!"
    />

    {/* <BooleanInput source="show_tax" /> */}
  </SimpleForm>
)
