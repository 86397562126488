import React from "react"
import {
  DateInput,
  NumberInput,
  BooleanInput,
  SimpleFormProps,
  TextInput,
  Labeled,
  SimpleForm,
  required,
  minValue,
  maxValue,
  number,
} from "react-admin"

import { OwnerRefField, OwnerRefInput } from "../../real_estate/owners"
import { PropertyRefField, PropertyRefInput } from "../../real_estate/properties"

type Props = Partial<SimpleFormProps> & {
  isExisting: boolean
}

export const QuotaForm: React.FC<Props> = ({ isExisting = false, ...rest }) => (
  <SimpleForm {...rest} spacing={2}>
    {isExisting ? (
      <Labeled source="customer_id" fullWidth>
        <OwnerRefField source="customer_id" />
      </Labeled>
    ) : (
      <OwnerRefInput source="customer_id" validate={[required()]} />
    )}

    {isExisting ? (
      <Labeled source="property_id" fullWidth>
        <PropertyRefField source="property_id" />
      </Labeled>
    ) : (
      <PropertyRefInput source="property_id" validate={[required()]} />
    )}

    {isExisting && <TextInput source="title" />}

    <NumberInput source="share_count" validate={[required(), number(), minValue(1), maxValue(8)]} />

    <DateInput source="valid_from" />
    <BooleanInput source="is_disabled" />

    <TextInput source="internal_notes" multiline rows={3} />
  </SimpleForm>
)
