import { FieldProps, useRecordContext } from "react-admin"

import { BillDto } from "@/types/Bill"

import { BookingBill } from "../../../components/BookingBill"

const BookingBillField = (props: Partial<FieldProps>) => {
  const { source } = props
  const record = useRecordContext(props) as BillDto

  return <BookingBill bill={record} title="" />
}

export default BookingBillField
