import { ReferenceInputProps, InputProps, ReferenceInput, SelectInput } from "react-admin"

interface Props extends Omit<InputProps, "source"> {
  source?: string
}

const ProductRefInput = (
  props: Props & Omit<Omit<ReferenceInputProps, "source">, "reference" | "children">,
) => (
  <ReferenceInput source="product_id" reference="products">
    <SelectInput
      {...props}
      fullWidth={props.fullWidth}
      optionText={(item) =>
        `${item.title ?? ""} ${item.product_code ? `(${item.product_code})` : ""}`
      }
    />
  </ReferenceInput>
)

ProductRefInput.defaultProps = {
  source: "product_id",
  addLabel: true,
}

export { ProductRefInput }
