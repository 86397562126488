import { InputProps, SelectInput } from "react-admin"

export const choices = [
  { id: "draft", name: "draft" },
  { id: "approved", name: "approved" },
  { id: "finalized", name: "finalized" },
  { id: "closed", name: "closed" },
  { id: "cancelled", name: "cancelled" },
]

export const BillStatusSelectInput: React.FC<InputProps> = (props) => (
  <SelectInput {...props} choices={choices} />
)
