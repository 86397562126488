import React from "react"
import {
  NumberInput,
  SimpleFormProps,
  TextInput,
  Labeled,
  SimpleForm,
  required,
  minValue,
  maxValue,
  number,
  FormDataConsumer,
} from "react-admin"

import { MoneyInput } from "../../../inputs/MoneyInput"
import { BillGroupRefField, BillGroupRefInput } from "../bill_groups"
import { ProductRefField, ProductRefInput } from "../products"
import { PricingModelSelectInput } from "./PricingModelSelectInput"
import { UnitSelectInput } from "./UnitSelectInput"

type Props = Partial<SimpleFormProps> & {
  isExisting: boolean
}

export const BillLineItemForm: React.FC<Props> = ({ isExisting = false }) => (
  <SimpleForm>
    {isExisting ? (
      <Labeled source="group_id" fullWidth>
        <BillGroupRefField source="group_id" />
      </Labeled>
    ) : (
      <BillGroupRefInput source="group_id" validate={[required()]} />
    )}

    {/*
    {isExisting ? (
      <Labeled source="source_product_id" fullWidth>
        <ProductRefField source="source_product_id" />
      </Labeled>
    ) : (
      <ProductRefInput source="source_product_id" />
    )}
    */}

    <NumberInput source="quantity" defaultValue={1} />

    <FormDataConsumer>
      {({ formData, ...rest }) => (
        <>
          {!formData.source_product_id && <TextInput source="product_code" />}
          <br />
          {!formData.source_product_id && <TextInput source="title" />}
          {/* {!formData.source_product_id && <TextInput source="title_de" />} */}
          <br />
          {!formData.source_product_id && <TextInput source="description" />}
          {/* {!formData.source_product_id && <TextInput source="description_de" />} */}
          <br />
          {!formData.source_product_id && (
            <PricingModelSelectInput source="pricing_model" validate={[required()]} />
          )}
        </>
      )}
    </FormDataConsumer>

    <FormDataConsumer>
      {({ formData, ...rest }) =>
        formData.pricing_model === "per_unit" && (
          <>
            <UnitSelectInput source="unit" />
            <NumberInput source="unit_count" />
          </>
        )
      }
    </FormDataConsumer>

    <MoneyInput source="price_amount" />

    {/* {!isExisting && <MoneyInput source="total" />} */}

    {/* <NumberInput source="vat_rate" validate={[number(), minValue(0), maxValue(100)]} /> */}
  </SimpleForm>
)
