import React from "react"
import {
  SimpleFormProps,
  Labeled,
  required,
  TabbedForm,
  FormTab,
  TextInput,
  DateInput,
  NumberInput,
} from "react-admin"

import { CalendarEntryRefField, CalendarEntryRefInput } from "../../scheduling/calendar_entries"
import { BillLangCodeSelectInput } from "./BillLangCodeSelectInput"
import { DocumentTypeSelectInput } from "./DocumentTypeSelectInput"

type Props = Partial<SimpleFormProps> & {
  isExisting: boolean
}

export const BillEditForm: React.FC<Props> = ({ isExisting = false, defaultValues }) => (
  <TabbedForm defaultValues={defaultValues}>
    <FormTab label="General">
      <Labeled source="booking_id" fullWidth>
        <CalendarEntryRefField source="booking_id" />
      </Labeled>

      <Labeled source="customer_id" fullWidth>
        <CalendarEntryRefField source="customer_id" />
      </Labeled>

      <Labeled source="property_id" fullWidth>
        <CalendarEntryRefField source="property_id" />
      </Labeled>

      <TextInput source="internal_notes" multiline rows={3} fullWidth />
    </FormTab>

    {/*
    <FormTab label="Payment">
      <TextInput source="current_payment_status" />
      <TextInput source="collection_method" />
      <NumberInput source="payed_amount" />
    </FormTab>
    */}

    <FormTab label="Document Content">
      <DocumentTypeSelectInput source="document_type" />
      <TextInput
        source="unique_number"
        helperText="invoice number or payment request number on a created document"
      />
      <BillLangCodeSelectInput source="language_code" />
      <DateInput source="billing_date" />
      {/* <DateInput source="supply_date" /> */}
      {/* <DateInput source="delivery_date" /> */}
      <DateInput source="period_start" />
      <DateInput source="period_end" />
      <DateInput source="due_date" />
      <NumberInput source="due_days" />
      <TextInput source="title_text" />
      <TextInput source="intro_text" multiline rows={3} fullWidth />
      {/* <TextInput source="footer_text" multiline rows={3} fullWidth /> */}
      <TextInput source="closing_text" multiline rows={3} fullWidth />
      {/* <TextInput source="description_text" multiline rows={3} fullWidth /> */}
      <TextInput source="property_label" />
    </FormTab>

    <FormTab label="Issuer">
      <TextInput source="issuer_name" />
      <TextInput source="issuer_address1" />
      <TextInput source="issuer_address2" />
      <TextInput source="issuer_address_city" />
      <TextInput source="issuer_address_postal_code" />
      <TextInput source="issuer_address_country" />
      <TextInput source="issuer_email" />
      <TextInput source="issuer_phone" />
      <TextInput source="issuer_tax_exempt" />
      <TextInput source="issuer_tax_id" />
      <TextInput source="issuer_vat_id" />
      <TextInput source="issuer_commercial_register_number" />
      <TextInput source="issuer_local_court" />

      <TextInput source="bank_name" />
      <TextInput source="bank_account_owner" />
      <TextInput source="bank_iban" />
      <TextInput source="bank_bic" />
    </FormTab>

    <FormTab label="Recipient">
      <TextInput source="recipient_name" />
      <TextInput source="recipient_address1" />
      <TextInput source="recipient_address2" />
      <TextInput source="recipient_address_city" />
      <TextInput source="recipient_address_postal_code" />
      <TextInput source="recipient_address_country" />
      <TextInput source="recipient_email" />
      <TextInput source="recipient_phone" />
      <TextInput source="recipient_tax_exempt" />
      <TextInput source="recipient_tax_id" />
      <TextInput source="recipient_vat_id" />
      <TextInput source="recipient_commercial_register_number" />
      <TextInput source="recipient_local_court" />
    </FormTab>
  </TabbedForm>
)
