import { StyleSheet, Text, View } from "@react-pdf/renderer"

import { BillGroupDto, LineItemDto } from "../../types/Bill"
import { LineItem, LineItemHeading } from "./LineItem"
import { getFormattedAmount } from "./utils"

const styles = StyleSheet.create({
  container: {
    border: "1 solid #ddd",
    marginBottom: "6mm",
    // backgroundColor: "#ff2",
    // backgroundColor: "#9df",
  },

  title: {
    padding: "2mm",
    backgroundColor: "#ddd",
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
  },

  content: {
    padding: "2mm",
  },

  description: {
    fontSize: 10,
    marginBottom: "4mm",
  },

  discount: {
    marginBottom: "4mm",
  },

  summaryTable: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  summaryTableLeft: {
    // backgroundColor: "red",
    // width: "60mm",
    justifyContent: "space-around",
  },
  summaryTableCenter: {},
  summaryTableRight: {
    // backgroundColor: "green",
    width: "35mm",
    justifyContent: "space-around",
    alignItems: "flex-end",
  },

  textLine: {
    marginBottom: "1mm",
  },
  textBold: {
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
  },
})

interface GroupDiscountProps {
  group: BillGroupDto
}

export const GroupDiscount = ({ group }: GroupDiscountProps) => {
  const discountFormatted = !!group.discount_percentage
    ? `-${group.discount_percentage}%`
    : getFormattedAmount(group.discount_amount)

  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "flex-end",
      }}
    >
      <View
        style={{
          backgroundColor: "red",
          width: "60mm",
          justifyContent: "space-around",
        }}
      >
        <Text>{group.discount_title}</Text>
      </View>

      <View
        style={{
          backgroundColor: "green",
          width: "35mm",
          justifyContent: "space-around",
          alignItems: "flex-end",
        }}
      >
        <Text>{discountFormatted}</Text>
      </View>
    </View>
  )
}

interface GroupProps {
  group: BillGroupDto
}

export const Group = ({ group }: GroupProps) => {
  const subtotalAmountFormatted = getFormattedAmount(group.subtotal_amount, group.subtotal_scale)
  const vatAmountFormatted = getFormattedAmount(group.vat_amount, group.vat_scale)
  const totalAmountFormatted = getFormattedAmount(group.total_amount, group.total_scale)
  const dueAmountFormatted = getFormattedAmount(group.due_amount, group.due_scale)
  const deferredAmountFormatted = getFormattedAmount(group.deferred_amount, group.deferred_scale)

  const vatRate = group.vat_rate
  const hasVat = !!vatRate

  return (
    <View style={styles.container} wrap={false}>
      {/* Group Header */}
      <View style={[styles.title]}>
        <Text>{group.title}</Text>
      </View>

      <View style={styles.content}>
        {!!group.description && (
          <View style={styles.description}>
            <Text>{group.description}</Text>
          </View>
        )}

        {group.line_items?.length < 1 && (
          <View style={{ backgroundColor: "red" }}>
            <Text>Empty group (no line items)</Text>
          </View>
        )}

        {/* LINE ITEMS */}

        <View style={{ marginBottom: "4mm" }}>
          <LineItemHeading />

          {group.line_items?.map((item: LineItemDto, idx) => (
            <LineItem item={item} key={idx} />
          ))}
        </View>

        {/* DISCOUNT */}

        {!!group.discount_amount && (
          <View style={styles.discount}>
            {(group.discount_amount || group.discount_percentage) && (
              <GroupDiscount group={group} />
            )}
          </View>
        )}

        {/* SUMMARY TABLE */}

        <View style={styles.summaryTable}>
          <View style={styles.summaryTableLeft}>
            {hasVat ? (
              <>
                <Text style={styles.textLine}>Gesamt netto</Text>
                <Text style={styles.textLine}>USt. {`${vatRate}%`}</Text>
                <Text style={styles.textLine}>Gesamt brutto</Text>
              </>
            ) : (
              <Text style={styles.textLine}>Gesamt</Text>
            )}
            {!!deferredAmountFormatted ?? <Text style={styles.textLine}>Zu zahlen</Text>}
            {!!deferredAmountFormatted ?? <Text style={styles.textLine}>Gestundet</Text>}
          </View>

          <View style={styles.summaryTableRight}>
            {hasVat ? (
              <>
                <Text style={styles.textLine}>{subtotalAmountFormatted ?? " "}</Text>
                <Text style={styles.textLine}>{vatAmountFormatted ?? " "}</Text>
                <Text style={styles.textLine}>{totalAmountFormatted ?? " "}</Text>
              </>
            ) : (
              <Text style={styles.textLine}>{totalAmountFormatted ?? " "}</Text>
            )}
            {!!deferredAmountFormatted ?? (
              <Text style={styles.textLine}>{dueAmountFormatted ?? " "}</Text>
            )}
            {!!deferredAmountFormatted ?? (
              <Text style={styles.textLine}>{deferredAmountFormatted}</Text>
            )}
          </View>
        </View>
      </View>
    </View>
  )
}
