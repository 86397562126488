import { ReferenceInputProps, InputProps, ReferenceInput, SelectInput } from "react-admin"

interface Props extends Omit<InputProps, "source"> {
  source?: string
}

const QuotaRefInput = (
  props: Props & Omit<Omit<ReferenceInputProps, "source">, "reference" | "children">,
) => (
  <ReferenceInput source="quota_id" reference="booking_quotas" perPage={150}>
    <SelectInput fullWidth={props.fullWidth} optionText="title" {...props} />
  </ReferenceInput>
)

QuotaRefInput.defaultProps = {
  source: "quota_id",
  addLabel: true,
}

export { QuotaRefInput }
