export enum PricingModelEnum {
  "flat_fee" = "flat_fee",
  "per_unit" = "per_unit",
}

export enum UnitEnum {
  "night" = "night",
  "guest" = "guest",
  "pet" = "pet",
}

export enum BillableAmountFromEnum {
  "vat" = "vat",
  "net" = "net",
  "gross" = "gross",
}
export interface LineItemDto {
  product_id: string
  product_code: string
  title: string
  title_de: string
  description: string
  description_de: string
  quantity: number
  pricing_model?: PricingModelEnum
  unit?: UnitEnum
  unit_count: number
  price_amount: number
  price_scale: number
  total_amount: number
  total_scale: number
  created_by: string
}

export interface BillGroupDto {
  category_id: string
  category_code: string
  title: string
  title_de: string
  description: string
  description_de: string
  vat_rate?: number
  discount_amount: number
  discount_percentage: number
  discount_title: string
  discount_title_de: string
  vat_amount?: number
  vat_scale?: number
  subtotal_amount: number
  subtotal_scale: number
  total_amount: number
  total_scale: number
  due_amount: number
  due_scale: number
  deferred_amount: number
  deferred_scale: number
  billable_amount_from?: BillableAmountFromEnum
  line_items_hidden: boolean
  line_items: LineItemDto[]
  created_by: string
}

export interface BillDto {
  id: string
  customer_id: string
  booking_id: string
  check_in_day: string
  check_out_day: string
  // discount_amount: number
  // discount_percentage: number
  // discount_title: string
  currency: string
  vat_amount?: number
  vat_scale?: number
  vat?: {
    rate?: number
    amount?: number
    scale?: number
  }[]
  subtotal_amount: number
  subtotal_scale: number
  total_amount: number
  total_scale: number
  due_amount: number
  due_scale: number
  deferred_amount: number
  deferred_scale: number

  unique_number?: string

  billing_date?: string
  supply_date?: string
  delivery_date?: string
  period_start?: string
  period_end?: string
  due_date?: string
  due_days?: number
  title_text?: string
  intro_text?: string
  footer_text?: string
  closing_text?: string
  description_text?: string
  issuer_name?: string
  issuer_address1?: string
  issuer_address2?: string
  issuer_address_city?: string
  issuer_address_postal_code?: string
  issuer_address_country?: string
  issuer_email?: string
  issuer_phone?: string
  issuer_tax_exempt?: string
  issuer_tax_id?: string
  issuer_vat_id?: string
  issuer_commercial_register_number?: string
  issuer_local_court?: string

  bank_name?: string
  bank_account_owner?: string
  bank_iban?: string
  bank_bic?: string

  recipient_name?: string
  recipient_address1?: string
  recipient_address2?: string
  recipient_address_city?: string
  recipient_address_postal_code?: string
  recipient_address_country?: string
  recipient_email?: string
  recipient_phone?: string
  recipient_tax_exempt?: string
  recipient_tax_id?: string
  recipient_vat_id?: string
  recipient_commercial_register_number?: string
  recipient_local_court?: string

  created_by: string

  groups: BillGroupDto[]
}
