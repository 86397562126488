import React from "react"
import { FunctionField, ReferenceField, ReferenceFieldProps, TextField } from "react-admin"

import { IProperty } from "@/types/IProperty"

interface Props {
  source?: string
}

const PropertyRefField: React.FC<
  Props & Omit<Omit<ReferenceFieldProps, "source">, "reference" | "children">
> = (props) => (
  <ReferenceField
    label="Properties"
    source="property_id"
    reference="properties"
    link="show"
    {...props}
  >
    {/* <TextField source="name" /> */}
    <FunctionField render={(record: IProperty) => `[${record.short_id}] ${record.name}`} />
  </ReferenceField>
)

PropertyRefField.defaultProps = {
  source: "property_id",
}

export { PropertyRefField }
