import moment from "moment"
import React from "react"
import {
  Calendar,
  momentLocalizer,
  NavigateAction,
  View,
  stringOrDate,
  SlotInfo,
} from "react-big-calendar"
import "react-big-calendar/lib/css/react-big-calendar.css"

import { ICalendarEntryWithNames } from "@/types/ICalendarEntry"

/**
 * @param arr any value that can be stringified
 * @returns string in format [arr[0]] / [arr[1]] / ... / [arr[n]]
 */
const intoStringArray = (arr: any[]): string => {
  const s = arr.reduce<string>((acc, val, index) => {
    if (val) {
      acc += `[${val}]`
    }
    if (index !== arr.length - 1) {
      acc += "  / "
    }
    return acc
  }, "")
  return s
}

const localizer = momentLocalizer(moment)

type EventCalendarViewProps = {
  entries: ICalendarEntryWithNames[] // CalendarEntry[]
  defaultDate?: string | Date
  onRangeChange: (start: Date, end: Date, view: View | undefined) => void
  onSelectEvent: (event: any) => void
  onSelectSlot: (slotInfo: SlotInfo) => void
}

export const EventCalendarView: React.FC<EventCalendarViewProps> = ({
  entries,
  defaultDate,
  onRangeChange,
  onSelectEvent,
  onSelectSlot,
}) => {
  const [events, setEvents] = React.useState<any[] | undefined>()

  React.useEffect(() => {
    setEvents(
      entries
        ? entries.map((entry) => ({
            ...entry,
            allDay: true,
            // title: entry.label,
            start: new Date(entry.start_date),
            end: new Date(entry.end_date),
            resource: entry.id,
          }))
        : [],
    )
  }, [entries])

  const handleRangeChange = (
    range: Date[] | { start: stringOrDate; end: stringOrDate },
    view: View | undefined,
  ) => {
    // console.log("[EventCalendarView] handleRangeChange# range", range)

    const start = Array.isArray(range) ? range[0] : range.start
    const end = Array.isArray(range) ? range[1] : range.end

    onRangeChange(
      typeof start === "string" ? new Date(start) : start,
      typeof end === "string" ? new Date(end) : end,
      view,
    )
  }

  const defaultAccessor = (event: any) => {
    return intoStringArray([
      event.current_status,
      event?.customer_name,
      event?.user_name,
      event.title,
    ])
  }

  return (
    <Calendar
      localizer={localizer}
      defaultDate={defaultDate ?? new Date()}
      defaultView="month"
      views={["month", "agenda"]}
      selectable="ignoreEvents"
      events={events}
      titleAccessor={defaultAccessor}
      tooltipAccessor={defaultAccessor}
      style={{
        height: "60vh",
        //   width: "100vh"
      }}
      onSelectEvent={onSelectEvent}
      onSelectSlot={onSelectSlot}
      onNavigate={(newDate: Date, view: View, action: NavigateAction) => {
        console.log("onNavigate", newDate, view, action)
      }}
      onRangeChange={handleRangeChange}
      onShowMore={(events, date) => {
        console.log("onShowMore", events, date)
      }}
    />
  )
}
