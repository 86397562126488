import BookIcon from "@mui/icons-material/Book"
import ChatBubbleIcon from "@mui/icons-material/ChatBubble"
import LabelIcon from "@mui/icons-material/Label"
import PeopleIcon from "@mui/icons-material/People"
import TagIcon from "@mui/icons-material/Tag"
import {
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material"
import { DashboardMenuItem, Menu, MenuItemLink, MenuProps } from "react-admin"
import { BillIcon } from "resources/billing/bills"
import { CategoryIcon } from "resources/billing/product_categories"
import { ProductIcon } from "resources/billing/products"
import { OwnerIcon } from "resources/real_estate/owners"
import { PropertyIcon } from "resources/real_estate/properties"
import { CalendarEntryIcon } from "resources/scheduling/calendar_entries"
import { CalendarIcon } from "resources/scheduling/calendars"

export const CustomMenu3 = (props: MenuProps) => (
  <>
    {/*
    <List
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Nested List Items
        </ListSubheader>
      }
    >
      <ListItemButton>
        <ListItemIcon>
          <ChatBubbleIcon />
        </ListItemIcon>
        <ListItemText primary="Sent mail" />
      </ListItemButton>
      <ListItemButton>
        <MenuItemLink
          to="/calendar_entries"
          primaryText="Events"
          leftIcon={<CalendarEntryIcon />}
        />
      </ListItemButton>
    </List>
    */}
    <Menu {...props}>
      <DashboardMenuItem />
      <Divider />
      <MenuItemLink to="/calendar_entries" primaryText="Events" leftIcon={<CalendarEntryIcon />} />
      <MenuItemLink to="/calendars" primaryText="Calendars" leftIcon={<CalendarIcon />} />
      <Divider />
      <MenuItemLink to="/customers" primaryText="Owners" leftIcon={<OwnerIcon />} />
      <MenuItemLink to="/ownerships" primaryText="Ownerships" leftIcon={<OwnerIcon />} />
      <MenuItemLink to="/properties" primaryText="Properties" leftIcon={<PropertyIcon />} />
      <MenuItemLink to="/guest_templates" primaryText="Guest Templates" leftIcon={<OwnerIcon />} />
      <Divider />
      <MenuItemLink
        to="/product_categories"
        primaryText="Product Categories"
        leftIcon={<CategoryIcon />}
      />
      <MenuItemLink to="/products" primaryText="Products" leftIcon={<ProductIcon />} />
      <Divider />
      <MenuItemLink to="/bills" primaryText="Bills" leftIcon={<BillIcon />} />
      <Divider />
      <MenuItemLink to="/tags" primaryText="Tags" leftIcon={<TagIcon />} />
      <Divider />
      <MenuItemLink to="/users" primaryText="Users" leftIcon={<PeopleIcon />} />
    </Menu>
  </>
)
