import React from "react"
import { FormProps, TextInput, BooleanInput, TabbedForm, FormTab } from "react-admin"

import { CountryIsoInput } from "../../../inputs/CountryIsoInput"

type Props = Partial<FormProps> & {
  isExisting: boolean
}

export const OwnerForm: React.FC<Props> = ({ isExisting = false, defaultValues }) => (
  <TabbedForm defaultValues={defaultValues}>
    <FormTab label="Owner Details">
      <TextInput source="name" label="Company Name" helperText="If customer is a company" />
      <TextInput
        source="first_name"
        helperText="First name of person or legal representative for company"
      />
      <TextInput
        source="last_name"
        helperText="Last name of person or legal representative for company"
      />
      <TextInput source="email" helperText="Will receive billing emails." />
      <TextInput source="phone_cell" />
      <TextInput source="phone_landline" />

      <TextInput source="address_field_1" label="Street + No." helperText={false} />
      <TextInput source="address_field_2" helperText={false} />
      <TextInput source="address_city" label="City" helperText={false} />
      <TextInput source="address_postal_code" label="Postal Code" helperText={false} />
      <CountryIsoInput
        source="address_country"
        label="Country"
        helperText="Full country name, eg. `Deutschland`"
      />

      <TextInput source="tax_id" label="Tax ID" helperText="Steuernummer" />
      <TextInput source="vat_id" label="VAT ID" helperText="USt. ID" />
      <TextInput source="commercial_register_number" helperText="Handelsregisternummer" />
      <TextInput source="local_court" helperText="Amtsgericht" />
      <TextInput disabled source="tax_exempt" />

      <TextInput source="internal_notes" multiline rows={3} />
    </FormTab>

    <FormTab label="Advanced">
      <BooleanInput
        source="feature_flags.generate_booking_bills"
        label="Auto booking bill generation"
        helperText="Automatically generates/saves bills for bookings and shows expected costs on checkout in app."
      />
      {/*
      <BooleanInput
        source="feature_flags.full_booking_rules_validation"
        label="Full auto booking rules validation"
      />
      */}
    </FormTab>
  </TabbedForm>
)
