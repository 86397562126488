import { CondOperator, QueryFilter, QuerySort, RequestQueryBuilder } from "@nestjsx/crud-request"
import { omitBy } from "lodash"
// import { stringify } from "querystring"
import { DataProvider, fetchUtils } from "react-admin"

import { countDiff } from "./utils"

/**
 * Maps react-admin queries to a nestjsx/crud powered REST API
 *
 * @see https://github.com/nestjsx/crud
 *
 * @example
 *
 * import React from 'react';
 * import { Admin, Resource } from 'react-admin';
 * import crudProvider from 'ra-data-nestjsx-crud';
 *
 * import { PostList } from './posts';
 *
 * const dataProvider = crudProvider('http://localhost:3000');
 * const App = () => (
 *     <Admin dataProvider={dataProvider}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */

// omitBy(newElements, (v, k) => prevElements[k] === v)

const composeFilter = (paramsFilter: any): QueryFilter[] => {
  const flatFilter = fetchUtils.flattenObject(paramsFilter)

  return Object.keys(flatFilter).map((key) => {
    const splitKey = key.split("__")

    let field = splitKey[0]
    let ops = splitKey[1]

    if (!ops) {
      if (
        typeof flatFilter[key] === "number" ||
        typeof flatFilter[key] === "boolean" ||
        flatFilter[key].match(/^\d+$/) ||
        // Matches UUID v4:
        flatFilter[key].match(
          /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
        )
      ) {
        ops = CondOperator.EQUALS
      } else {
        ops = CondOperator.CONTAINS
      }
    } else {
      ops = "$" + ops
    }

    if (field.startsWith("_") && field.includes(".")) {
      field = field.split(/\.(.+)/)[1]
    }

    return { field, operator: ops, value: flatFilter[key] } as QueryFilter
  })
}

const composeQueryParams = (queryParams: any = {}): string => {
  // return stringify(fetchUtils.flattenObject(queryParams))
  return new URLSearchParams(fetchUtils.flattenObject(queryParams)).toString()
}

const mergeEncodedQueries = (...encodedQueries: any) =>
  encodedQueries.map((query: any) => query).join("&")

export const dataProviderFactory = (
  apiUrl: string,
  leadApiUrl: string,
  httpClient = fetchUtils.fetchJson,
): DataProvider => {
  const getBaseUrl = (resource: string) => {
    if (resource === "leads") {
      return `${leadApiUrl}/direct`
    }

    return apiUrl
  }

  return {
    getList: (resource, params) => {
      const { page, perPage } = params.pagination
      const { q: queryParams, ...filter } = params.filter || {}

      const encodedQueryParams = composeQueryParams(queryParams)
      const composedFilter = composeFilter(filter)

      const encodedQueryFilter = RequestQueryBuilder.create({
        filter: composedFilter,
      })
        .setLimit(perPage)
        .setPage(page)
        .sortBy(params.sort as QuerySort)
        .setOffset((page - 1) * perPage)
        .query()

      const query = mergeEncodedQueries(encodedQueryParams, encodedQueryFilter)
      const url = `${getBaseUrl(resource)}/${resource}?${query}`

      return httpClient(url).then(({ json }) => ({
        data: json.data,
        total: json.total,
      }))
    },

    getOne: (resource, params) =>
      httpClient(`${getBaseUrl(resource)}/${resource}/${params.id}`).then(({ json }) => ({
        data: json,
      })),

    getMany: (resource, params) => {
      const query = RequestQueryBuilder.create()
        .setFilter({
          field: "id",
          // operator: CondOperator.IN,
          operator:
            Array.isArray(params.ids) && params.ids.length > 1
              ? CondOperator.IN
              : CondOperator.EQUALS,
          value: `${params.ids}`,
        })
        .query()

      const url = `${getBaseUrl(resource)}/${resource}?${query}`

      return httpClient(url).then(({ json }) => ({ data: json.data }))
    },

    getManyReference: (resource, params) => {
      const { page, perPage } = params.pagination
      const { q: queryParams, ...otherFilters } = params.filter || {}
      const filter: QueryFilter[] = composeFilter(otherFilters)

      filter.push({
        field: params.target,
        operator: CondOperator.EQUALS,
        value: params.id,
      })

      const encodedQueryParams = composeQueryParams(queryParams)
      const encodedQueryFilter = RequestQueryBuilder.create({
        filter,
      })
        .sortBy(params.sort as QuerySort)
        .setLimit(perPage)
        .setOffset((page - 1) * perPage)
        .query()

      const query = mergeEncodedQueries(encodedQueryParams, encodedQueryFilter)

      const url = `${getBaseUrl(resource)}/${resource}?${query}`

      return httpClient(url).then(({ json }) => ({
        data: json.data,
        total: json.total,
      }))
    },

    update: (resource, params) => {
      // no need to send all fields, only updated fields are enough
      let data = countDiff(params.data, params.previousData)

      // Empty strings to nulls
      // data = map(data, (d) => (d === "" ? null : d))

      return httpClient(`${getBaseUrl(resource)}/${resource}/${params.id}`, {
        method: "PATCH",
        body: JSON.stringify(data),
      }).then(({ json }) => ({ data: json }))
    },

    updateMany: (resource, params) =>
      Promise.all(
        params.ids.map((id) =>
          httpClient(`${getBaseUrl(resource)}/${resource}/${id}`, {
            method: "PUT",
            body: JSON.stringify(params.data),
          }),
        ),
      ).then((responses) => ({
        data: responses.map(({ json }) => json),
      })),

    create: (resource, params) => {
      const data = omitBy(params.data, (value) => value === "")

      return httpClient(`${getBaseUrl(resource)}/${resource}`, {
        method: "POST",
        body: JSON.stringify(data),
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }))
    },

    delete: (resource, params) =>
      httpClient(`${getBaseUrl(resource)}/${resource}/${params.id}`, {
        method: "DELETE",
      }).then(({ json }) => ({ data: { ...json, id: params.id } })),

    deleteMany: (resource, params) =>
      Promise.all(
        params.ids.map((id) =>
          httpClient(`${getBaseUrl(resource)}/${resource}/${id}`, {
            method: "DELETE",
          }),
        ),
      ).then((responses) => ({ data: responses.map(({ json }) => json) })),
  }
}
