import axios from "axios"
import firebase from "firebase/compat/app"
import "firebase/compat/auth"

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: { Accept: "application/vnd.github.v3+json" },
  // timeout: 1000,
})

apiClient.interceptors.request.use(
  async (config) => {
    const token = await firebase.app().auth().currentUser?.getIdToken()

    if (token) {
      config.headers = {
        ...(config.headers || {}),
        Authorization: `Bearer ${token}`,
      }
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)
