import { Alert, Box, Card, CardContent, Typography } from "@mui/material"
import {
  Datagrid,
  FunctionField,
  Labeled,
  Loading,
  ReferenceManyField,
  TextField,
  useGetOne,
  useRecordContext,
} from "react-admin"
import { OwnedSharesField } from "resources/real_estate/ownerships/OwnedSharesField"
import { PropertyRefField } from "resources/real_estate/properties"

import { ICalendar } from "@/types/ICalendar"
import { ICalendarEntry } from "@/types/ICalendarEntry"
import { IOwnership } from "@/types/IOwnership"

export const OwnerInfoCard = () => {
  const record = useRecordContext<ICalendarEntry>()
  const {
    data: calendar,
    isLoading,
    error,
  } = useGetOne<ICalendar>("calendars", { id: record.calendar_id })

  if (isLoading) {
    return <Loading />
  }
  if (error) {
    return <p>ERROR</p>
  }

  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Ownership Info
        </Typography>

        <ReferenceManyField
          source="customer_id"
          reference="ownerships"
          target="customer_id"
          filter={{
            property_id: calendar?.property_id,
          }}
          sort={{ field: "created_at", order: "ASC" }}
        >
          <Datagrid
            bulkActionButtons={false}
            empty={<Alert severity="info">No properties assigned.</Alert>}
          >
            {/* <PropertyRefField source="property_id" /> */}
            <OwnedSharesField source="owned_shares" />
            <FunctionField
              label="Number of available nights (theoretically)"
              render={(record: IOwnership) => <i>NOT IMPLEMENTED</i>}
            />
            <TextField source="internal_notes" />
          </Datagrid>
        </ReferenceManyField>

        <Box mb={2} />

        <Labeled label="Number of total upcoming confirmed stays">
          <FunctionField render={(record: ICalendarEntry) => <i>NOT IMPLEMENTED</i>} />
        </Labeled>
      </CardContent>
    </Card>
  )
}
