import React from "react"
import { FunctionField, ReferenceField, ReferenceFieldProps, TextField } from "react-admin"

import { ICalendar } from "@/types/ICalendar"

interface Props {
  source?: string
}

const CalendarRefField: React.FC<
  Props & Omit<Omit<ReferenceFieldProps, "source">, "reference" | "children">
> = (props) => (
  <ReferenceField
    label="Calendar"
    source="calendar_id"
    reference="calendars"
    link="show"
    {...props}
  >
    <FunctionField
      render={(record: ICalendar) =>
        record.property_info?.short_id
          ? `[${record.property_info?.short_id}] ${record.property_info?.name ?? record.title}`
          : record.title
      }
    />
  </ReferenceField>
)

CalendarRefField.defaultProps = {
  source: "calendar_id",
}

export { CalendarRefField }
