/**
 * @see https://github.com/benwinding/react-admin-firebase
 */
import { FirebaseAuth, User } from "@firebase/auth-types"
import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import { AuthProvider as RaAuthProvider, UserIdentity } from "react-admin"

import { AuthOptions } from "./AuthOptions"
import { FirebaseWrapper } from "./FirebaseWrapper"
import { messageTypes } from "./messageTypes"
import { retrieveStatusTxt } from "./retrieveStatusTxt"

class AuthClient {
  private auth: FirebaseAuth

  constructor(firebaseConfig: {}, optionsInput?: AuthOptions) {
    const options = optionsInput || {}

    // console.log("Auth Client: initializing...", { firebaseConfig, options })

    const fireWrapper = new FirebaseWrapper()
    fireWrapper.init(firebaseConfig, options)

    this.auth = fireWrapper.auth()
    options.persistence && this.setPersistence(options.persistence)
  }

  setPersistence(persistenceInput: "session" | "local" | "none") {
    let persistenceResolved: string

    switch (persistenceInput) {
      case "local":
        persistenceResolved = firebase.auth.Auth.Persistence.LOCAL
        break
      case "none":
        persistenceResolved = firebase.auth.Auth.Persistence.NONE
        break
      case "session":
      default:
        persistenceResolved = firebase.auth.Auth.Persistence.SESSION
        break
    }

    console.log("setPersistence", { persistenceInput, persistenceResolved })

    this.auth.setPersistence(persistenceResolved).catch((error) => console.error(error))
  }

  public async HandleAuthLogin(params: { username: string; password: string }) {
    const { username, password } = params

    if (username && password) {
      try {
        const user = await this.auth.signInWithEmailAndPassword(username, password)
        // console.log("HandleAuthLogin: user sucessfully console.logged in", { user })
        return user
      } catch (e) {
        // console.log("HandleAuthLogin: invalid credentials", { params })
        throw new Error("Login error: invalid credentials")
      }
    } else {
      return this.getUserLogin()
    }
  }

  public HandleAuthLogout() {
    return this.auth.signOut()
  }

  public HandleAuthError(errorHttp: messageTypes.HttpErrorType) {
    // console.log("HandleAuthLogin: invalid credentials", { errorHttp })
    const status = !!errorHttp && errorHttp.status
    const statusTxt = retrieveStatusTxt(status)

    if (statusTxt === "ok") {
      console.log("API is actually authenticated")
      return Promise.resolve()
    }

    console.warn("Recieved authentication error from API")
    return Promise.reject()
  }

  public async HandleAuthCheck(): Promise<void> {
    return this.getUserLogin() as any // Prevents breaking change
  }

  public getUserLogin(): Promise<User> {
    return new Promise((resolve, reject) => {
      if (this.auth.currentUser) return resolve(this.auth.currentUser)

      const unsubscribe = this.auth.onAuthStateChanged((user) => {
        unsubscribe()

        if (user) {
          resolve(user)
        } else {
          reject()
        }
      })
    })
  }

  public getCurrentUser(): User | undefined {
    return this.auth.currentUser ?? undefined
  }

  public async HandleGetPermissions() {
    try {
      const user = await this.getUserLogin()
      const token = await user.getIdTokenResult()

      // console.log("[HandleGetPermissions] token", token)

      return token.claims?.roles
    } catch (e) {
      console.log("HandleGetPermission: no user is logged in or tokenResult error", {
        e,
      })

      return null
    }
  }

  public async HandleGetIdentity(): Promise<UserIdentity> {
    try {
      const { uid, displayName, photoURL } = await this.getUserLogin()
      const identity: UserIdentity = {
        id: uid,
        fullName: `${displayName ?? ""}`,
        avatar: `${photoURL ?? ""}`,
      }

      return identity
    } catch (e) {
      console.log("HandleGetIdentity: no user is logged in", {
        e,
      })

      return null as any
    }
  }

  public async HandleGetJWTAuthTime() {
    try {
      const user = await this.getUserLogin()
      // @ts-ignore
      const token = await user.getIdTokenResult()

      return token.authTime
    } catch (e) {
      console.log("HandleGetJWTAuthTime: no user is logged in or tokenResult error", {
        e,
      })

      return null
    }
  }

  public async HandleGetJWTExpirationTime() {
    try {
      const user = await this.getUserLogin()
      // @ts-ignore
      const token = await user.getIdTokenResult()

      return token.expirationTime
    } catch (e) {
      console.log("HandleGetJWTExpirationTime: no user is logged in or tokenResult error", {
        e,
      })

      return null
    }
  }

  public async HandleGetJWTSignInProvider() {
    try {
      const user = await this.getUserLogin()
      // @ts-ignore
      const token = await user.getIdTokenResult()

      return token.signInProvider
    } catch (e) {
      console.log("HandleGetJWTSignInProvider: no user is logged in or tokenResult error", {
        e,
      })

      return null
    }
  }

  public async HandleGetJWTIssuedAtTime() {
    try {
      const user = await this.getUserLogin()
      // @ts-ignore
      const token = await user.getIdTokenResult()

      return token.issuedAtTime
    } catch (e) {
      console.log("HandleGetJWTIssuedAtTime: no user is logged in or tokenResult error", {
        e,
      })

      return null
    }
  }

  public async HandleGetJWTToken() {
    try {
      const user = await this.getUserLogin()
      // @ts-ignore
      const token = await user.getIdTokenResult()

      return token.token
    } catch (e) {
      console.log("HandleGetJWTIssuedAtTime: no user is logged in or tokenResult error", {
        e,
      })

      return null
    }
  }
}

export function authProviderFactory(firebaseConfig: {}, options: AuthOptions): RaAuthProvider {
  VerifyAuthProviderArgs(firebaseConfig, options)

  // logger.SetEnabled(!!options?.logging)
  const auth = new AuthClient(firebaseConfig, options)

  const provider: RaAuthProvider = {
    // React Admin Interface
    login: (params) => auth.HandleAuthLogin(params),
    logout: () => auth.HandleAuthLogout(),
    checkAuth: () => auth.HandleAuthCheck(),
    checkError: (error) => auth.HandleAuthError(error),
    getPermissions: () => auth.HandleGetPermissions(),
    getIdentity: () => auth.HandleGetIdentity(),
    // Custom Functions
    getAuthUser: () => auth.getUserLogin(),
    getCurrentUser: () => auth.getCurrentUser(),
    getJWTAuthTime: () => auth.HandleGetJWTAuthTime(),
    getJWTExpirationTime: () => auth.HandleGetJWTExpirationTime(),
    getJWTSignInProvider: () => auth.HandleGetJWTSignInProvider(),
    getJWTClaims: () => auth.HandleGetPermissions(),
    getJWTToken: () => auth.HandleGetJWTToken(),
  }
  return provider
}

function VerifyAuthProviderArgs(firebaseConfig: {}, options: AuthOptions) {
  const hasNoApp = !options || !options.app
  const hasNoConfig = !firebaseConfig

  if (hasNoConfig && hasNoApp) {
    throw new Error(
      "Please pass the Firebase firebaseConfig object or options.app to the FirebaseAuthProvider",
    )
  }
}
