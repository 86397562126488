import React from "react"
import {
  SimpleFormProps,
  TextInput,
  Labeled,
  required,
  NumberInput,
  FormTab,
  TabbedForm,
} from "react-admin"
import { PropertyRefField, PropertyRefInput } from "resources/real_estate/properties"
import { BookingTypeSelectInput } from "resources/scheduling/BookingTypeSelectInput"

import { DocumentTypeSelectInput } from "../bills/DocumentTypeSelectInput"

type Props = Partial<SimpleFormProps> & {
  isExisting: boolean
}

export const IssuerProfileForm: React.FC<Props> = ({ isExisting = false }) => (
  <TabbedForm>
    <FormTab label="Profile Details">
      {isExisting ? (
        <Labeled source="property_id" fullWidth>
          <PropertyRefField source="property_id" />
        </Labeled>
      ) : (
        <PropertyRefInput source="property_id" validate={[required()]} />
      )}

      <BookingTypeSelectInput
        source="booking_type"
        helperText="Defines to which type of bookings this profile is applicable"
      />

      <TextInput
        source="profile_name"
        helperText="Can be empty. Descriptive name for the profile"
      />
      <TextInput source="internal_notes" multiline rows={3} />
    </FormTab>

    <FormTab label="Billing Contact">
      <TextInput
        source="full_name"
        helperText="Name of the company issuing the invoice or payment request"
      />
      <TextInput source="email" />
      <TextInput source="phone" />
      <TextInput source="address_field_1" helperText="Street and house number" />
      <TextInput source="address_field_2" />
      <TextInput source="address_city" />
      <TextInput source="address_postal_code" />
      <TextInput source="address_country" helperText="Full country name, eg. `Deutschland`" />
      <TextInput source="tax_id" label="Tax ID" helperText="Steuernummer" />
      <TextInput source="vat_id" label="VAT ID" helperText="USt. ID" />
      <TextInput source="commercial_register_number" helperText="Handelsregisternummer" />
      <TextInput source="local_court" helperText="Amtsgericht" />
      <TextInput
        disabled
        source="tax_exempt"
        helperText="(This field is not yet ready for usage)"
      />
    </FormTab>

    <FormTab label="Bank">
      <TextInput source="bank_account_owner" />
      <TextInput source="bank_name" />
      <TextInput source="bank_iban" />
      <TextInput source="bank_bic" />
    </FormTab>

    <FormTab label="Bill Settings">
      <DocumentTypeSelectInput
        source="default_document_type"
        helperText="Default document type that will be created for a bill"
      />
      <TextInput
        source="default_bill_property_label"
        helperText="Can be used as an alternative name of the property on a bill document"
      />
      <NumberInput
        source="default_bill_due_days"
        helperText="Overwrite the bills default due days"
      />
      <TextInput
        source="default_bill_intro_text"
        helperText="Overwrite the bills default intro text"
      />
    </FormTab>
  </TabbedForm>
)
