import React from "react"
import { SimpleForm, SimpleFormProps, TextInput } from "react-admin"

import { BillRefInput } from "../bills"
import { BillPaymentStatusSelectInput } from "./inputs/BillPaymentStatusSelectInput"

type Props = Partial<SimpleFormProps> & {
  isExisting: boolean
}

export const BillPaymentStatusForm: React.FC<Props> = ({ isExisting = false, ...rest }) => (
  <SimpleForm {...rest}>
    <BillRefInput source="bill_id" />

    <BillPaymentStatusSelectInput source="status" />

    <TextInput source="internal_notes" multiline rows={3} />
  </SimpleForm>
)
