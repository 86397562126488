import { EUR } from "@dinero.js/currencies"
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import { dinero, isZero, toFormat } from "dinero.js"

import { BillDto, BillGroupDto, LineItemDto, PricingModelEnum } from "../types/Bill"

const transformer = ({ amount, currency }: any) =>
  Intl.NumberFormat("de-DE", { style: "currency", currency: currency.code }).format(amount)

const getFormattedAmount = (amountInEuroCents: number | undefined, scale?: number): string => {
  if (!amountInEuroCents) return ""

  const monetary = dinero({ amount: amountInEuroCents, currency: EUR, scale })

  if (isZero(monetary)) return "-"

  return toFormat(monetary, transformer)
}

interface LineItemProps {
  item: LineItemDto
}

export const LineItem = ({ item }: LineItemProps) => {
  const totalAmountFormatted = getFormattedAmount(item.total_amount, item.total_scale)
  const priceFormatted = getFormattedAmount(item.price_amount, item.price_scale)
  let description: string | undefined = undefined

  if (item.pricing_model === PricingModelEnum.per_unit) {
    description = item.unit
  } else if (item.pricing_model === PricingModelEnum.flat_fee) {
    description = `flat fee`
  }

  return (
    <TableRow>
      <TableCell>{item.title}</TableCell>
      <TableCell>{description}</TableCell>
      <TableCell align="right">{item.unit_count}</TableCell>
      <TableCell align="right">{priceFormatted}</TableCell>
      <TableCell align="right">{totalAmountFormatted}</TableCell>
    </TableRow>
  )
}

interface GroupDiscountProps {
  group: BillGroupDto
}

export const GroupDiscount = ({ group }: GroupDiscountProps) => {
  const discountFormatted = !!group.discount_percentage
    ? `-${group.discount_percentage}%`
    : getFormattedAmount(group.discount_amount)

  return (
    <TableRow>
      <TableCell />
      <TableCell colSpan={3}>{group.discount_title}</TableCell>
      <TableCell align="right">{discountFormatted}</TableCell>
    </TableRow>
  )
}

interface GroupProps {
  group: BillGroupDto
}

export const Group = ({ group }: GroupProps) => {
  const subtotalAmountFormatted = getFormattedAmount(group.subtotal_amount, group.subtotal_scale)
  const vatAmountFormatted = getFormattedAmount(group.vat_amount, group.vat_scale)
  const totalAmountFormatted = getFormattedAmount(group.total_amount, group.total_scale)
  const dueAmountFormatted = getFormattedAmount(group.due_amount, group.due_scale)
  const deferredAmountFormatted = getFormattedAmount(group.deferred_amount, group.deferred_scale)

  const vatRate = group.vat_rate
  const title = `${group.title} (vat rate ${vatRate || "0"}%)`

  return (
    <Card variant="outlined">
      <CardHeader title={title} />
      <CardContent>
        {!!group.description && (
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {group.description}
          </Typography>
        )}

        {group.line_items?.length < 1 && (
          <Alert severity="warning">Empty group (no line items)</Alert>
        )}

        <Stack spacing={2}>
          <TableContainer>
            <Table
            // sx={{ minWidth: 600 }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Item</TableCell>
                  <TableCell>Unit</TableCell>
                  <TableCell align="right">Qty.</TableCell>
                  <TableCell align="right">Per Unit</TableCell>
                  <TableCell align="right">Sum</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {group.line_items?.map((item: LineItemDto) => (
                  <LineItem item={item} />
                ))}

                {(group.discount_amount || group.discount_percentage) && (
                  <GroupDiscount group={group} />
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TableContainer>
            <Table
              // sx={{ minWidth: 600 }}
              size="small"
            >
              <TableBody>
                <TableRow>
                  <TableCell rowSpan={5} />
                  <TableCell rowSpan={5} />
                  <TableCell colSpan={3}>Subtotal (Gesamt Netto)</TableCell>
                  <TableCell align="right">{subtotalAmountFormatted}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>VAT</TableCell>
                  <TableCell align="right">{`${vatRate}%`}</TableCell>
                  <TableCell align="right">{vatAmountFormatted}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={3}>Total (Gesamt Brutto)</TableCell>
                  <TableCell align="right">{totalAmountFormatted}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={3}>Due Amount (Zu zahlen)</TableCell>
                  <TableCell align="right">{dueAmountFormatted}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={3}>Deferred Amount (Gestundet)</TableCell>
                  <TableCell align="right">{deferredAmountFormatted}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </CardContent>
    </Card>
  )
}

interface BillProps {
  bill?: BillDto
  title?: string
}

export const BookingBill = ({ bill, title = "Preview bill (not saved)" }: BillProps) => {
  const groups = bill && Array.isArray(bill?.groups) && bill?.groups.length > 0 ? bill.groups : []
  const isEmpty = groups.length < 1
  const subtotalAmountFormatted = getFormattedAmount(bill?.subtotal_amount, bill?.subtotal_scale)
  const vatAmountFormatted = getFormattedAmount(bill?.vat_amount, bill?.vat_scale)
  const totalAmountFormatted = getFormattedAmount(bill?.total_amount, bill?.total_scale)
  const dueAmountFormatted = getFormattedAmount(bill?.due_amount, bill?.due_scale)
  const deferredAmountFormatted = getFormattedAmount(bill?.deferred_amount, bill?.deferred_scale)

  if (!bill) return <div>Loading...</div>

  return (
    <Card>
      <CardHeader
        title="Abrechnungsübersicht"
        subheader={
          <>
            Check-in:{" "}
            {bill?.check_in_day
              ? Intl.DateTimeFormat("de-DE").format(new Date(bill?.check_in_day))
              : "N/A"}
            , check-out:{" "}
            {bill?.check_out_day
              ? Intl.DateTimeFormat("de-DE").format(new Date(bill?.check_out_day))
              : "N/A"}
          </>
        }
      />
      <CardContent>
        <Stack spacing={2}>
          {groups.map((group, idx) => (
            <Group key={idx} group={group} />
          ))}

          {isEmpty && <Alert severity="warning">Empty bill (no groups)</Alert>}

          <Card variant="outlined">
            <CardHeader title="Summary" />
            <CardContent>
              <TableContainer>
                <Table
                  // sx={{ minWidth: 600 }}
                  size="small"
                >
                  <TableRow>
                    <TableCell rowSpan={7} />
                    <TableCell colSpan={3}>Subtotal (Gesamt Netto)</TableCell>
                    <TableCell align="right">{subtotalAmountFormatted}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={3}>VAT total (Enthaltene USt)"</TableCell>
                    <TableCell align="right">{vatAmountFormatted}</TableCell>
                  </TableRow>

                  {!!bill?.vat &&
                    bill.vat.map((vg) => (
                      <TableRow>
                        <TableCell colSpan={2} align="right">
                          VAT
                        </TableCell>
                        <TableCell align="right">{vg.rate}%</TableCell>
                        <TableCell align="right">
                          {getFormattedAmount(vg.amount, vg.scale)}
                        </TableCell>
                      </TableRow>
                    ))}

                  <TableRow>
                    <TableCell colSpan={3}>Total (Gesamt Brutto)</TableCell>
                    <TableCell align="right">{totalAmountFormatted}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3}>Due amount (Zu zahlen)</TableCell>
                    <TableCell align="right">{dueAmountFormatted}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3}>Deferred amount (Gestundet)</TableCell>
                    <TableCell align="right">{deferredAmountFormatted}</TableCell>
                  </TableRow>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Stack>
      </CardContent>
    </Card>
  )
}
