import {
  Datagrid,
  List,
  Create,
  Edit,
  TextField,
  ShowButton,
  EditButton,
  Show,
  DateField,
  NumberField,
  RedirectionSideEffect,
  TabbedShowLayout,
  Tab,
  BooleanField,
} from "react-admin"

import { OwnerRefField, OwnerRefInput } from "../owners"
import { PropertyRefField, PropertyRefInput } from "../properties"
import { OwnedSharesField } from "./OwnedSharesField"
import { OwnershipForm } from "./OwnershipForm"

const listFilters = [
  <PropertyRefInput source="property_id" alwaysOn />,
  <OwnerRefInput source="customer_id" alwaysOn />,
]

export const OwnershipList = () => (
  <List filters={listFilters} exporter={false} sort={{ field: "created_at", order: "DESC" }}>
    <Datagrid rowClick="show">
      <PropertyRefField source="property_id" />
      <OwnerRefField source="customer_id" />
      <BooleanField source="is_disabled" />
      <OwnedSharesField source="owned_shares" />
      <DateField source="created_at" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
)

export const OwnershipShow = () => (
  <Show>
    <TabbedShowLayout spacing={2}>
      <Tab label="Ownership Details">
        <PropertyRefField source="property_id" />
        <OwnerRefField source="customer_id" />
        <BooleanField source="is_disabled" />
        <OwnedSharesField source="owned_shares" />
        <TextField source="internal_notes" />
      </Tab>

      <Tab label="SEPA">
        <BooleanField source="has_sepa" />
        <TextField source="sepa_mandate_ref" label="SEPA Unique Mandate Reference" />
        <TextField source="sepa_merchant_name" label="SEPA Merchant Name" />
        <DateField source="sepa_signature_date" label="SEPA Signature Date" />
        {/* <TextField source="sepa_payment_type" /> */}
      </Tab>

      <Tab label="Meta">
        <TextField source="id" />
        <DateField source="created_at" showTime />
        <DateField source="updated_at" showTime />
        <TextField source="created_by" />
        <NumberField source="version" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

const redirect: RedirectionSideEffect = (resource, id, data) =>
  data ? `properties/${data.property_id}/show/ownerships` : "show"

export const OwnershipCreate = () => (
  <Create redirect={redirect}>
    <OwnershipForm isExisting={false} />
  </Create>
)

export const OwnershipEdit = () => (
  <Edit mutationMode="pessimistic" redirect={redirect}>
    <OwnershipForm isExisting />
  </Edit>
)
