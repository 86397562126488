import { Page, StyleSheet, Text, View } from "@react-pdf/renderer"

import { BillDto } from "../../types/Bill"
import { ICalendarEntry } from "../../types/ICalendarEntry"
import { IProperty } from "../../types/IProperty"
import { Group } from "./Group"
import { formatDate, getFormattedAmount, liquidEngine } from "./utils"

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    justifyContent: "space-between",
    // backgroundColor: "#3ff",
    // backgroundColor: "#ff2",
    // backgroundColor: "#9df",
    paddingVertical: "6mm",
    paddingHorizontal: "20mm",

    fontFamily: "Helvetica",
    fontSize: 11,
  },
  head: {
    // marginBottom: "10mm",
  },

  content: {},

  summary: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  summaryTableLeft: {
    // backgroundColor: "red",
    width: "60mm",
    justifyContent: "space-around",
  },
  summaryTableCenter: {
    // backgroundColor: "orange",
    width: "20mm",
    justifyContent: "space-around",
    alignItems: "flex-end",
  },
  summaryTableRight: {
    // backgroundColor: "green",
    width: "35mm",
    justifyContent: "space-around",
    alignItems: "flex-end",
  },

  summaryHighlight: {
    // marginBottom: "1mm",
    // borderBottom: "0.5mm solid black",
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
    textDecoration: "underline",
  },

  footer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    fontSize: 10,
  },
  footerLeft: {},
  footerCenter: {},
  footerRight: {
    alignItems: "flex-end",
  },

  section: {
    flexGrow: 1,
    marginBottom: "6mm",
    // backgroundColor: "lightblue",
  },

  textLine: {
    marginBottom: "1mm",
  },
  textBold: {
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
  },
})

interface BillProps {
  bill?: BillDto
  booking?: ICalendarEntry
  property?: IProperty
  title?: string
}

export const BookingBillPdf = ({
  bill,
  booking,
  property,
  title = "Preview bill (not saved)",
}: BillProps) => {
  const groups = bill && Array.isArray(bill?.groups) && bill?.groups.length > 0 ? bill.groups : []
  const isEmpty = groups.length < 1
  const subtotalAmountFormatted = getFormattedAmount(bill?.subtotal_amount, bill?.subtotal_scale)
  const vatAmountFormatted = getFormattedAmount(bill?.vat_amount, bill?.vat_scale)
  const totalAmountFormatted = getFormattedAmount(bill?.total_amount, bill?.total_scale)
  const dueAmountFormatted = getFormattedAmount(bill?.due_amount, bill?.due_scale)
  const deferredAmountFormatted = getFormattedAmount(bill?.deferred_amount, bill?.deferred_scale)

  if (!bill || !booking || !property) return <div>Loading...</div>

  const liquidContext = { bill, booking, property }
  const bill_intro_text =
    !!bill.intro_text && liquidEngine.parseAndRenderSync(bill.intro_text, liquidContext)
  const bill_closing_text =
    !!bill.closing_text && liquidEngine.parseAndRenderSync(bill.closing_text, liquidContext)

  const hasVAT = Array.isArray(bill?.vat) && bill?.vat.length > 0

  return (
    <Page size="A4" style={styles.page}>
      {/* Heading */}
      <View style={styles.head}>
        <View style={{ marginBottom: 30 }}>
          <Text>
            {bill.issuer_name} | {bill.issuer_address1}{" "}
            {!!bill.issuer_address2 && `| ${bill.issuer_address2}`}
          </Text>
        </View>

        <Text style={styles.textLine}>{bill.recipient_name}</Text>
        <Text style={styles.textLine}>{bill.recipient_address1}</Text>
        <Text style={styles.textLine}>{bill.recipient_address2}</Text>
        <Text style={styles.textLine}>
          {bill.recipient_address_postal_code} {bill.recipient_address_city}
        </Text>
        <Text style={styles.textLine}>{bill.recipient_address_country}</Text>
      </View>

      {/* Content */}
      <View style={styles.content}>
        <View
          style={[
            styles.section,
            {
              alignItems: "flex-end",
            },
          ]}
        >
          <View style={{ width: "8cm" }}>
            <View
              style={[
                styles.textBold,
                {
                  borderBottom: "1mm solid black",
                  paddingBottom: "1mm",
                  marginBottom: "2mm",
                },
              ]}
            >
              <Text>{bill.title_text}</Text>
            </View>

            <View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text style={styles.textLine}>Nummer</Text>
                <Text style={styles.textLine}>{bill.unique_number}</Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text style={styles.textLine}>Datum</Text>
                <Text style={styles.textLine}>{formatDate(bill?.billing_date)}</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.section}>
          <Text>{bill_intro_text}</Text>
        </View>

        {groups.map((group, idx) => (
          <Group key={idx} group={group} />
        ))}

        <View style={styles.section}>
          {isEmpty && (
            <View style={{ backgroundColor: "red" }}>
              <Text>Empty bill (no groups)</Text>
            </View>
          )}

          {/* SUMMARY */}

          <View style={styles.summary} wrap={false}>
            <View style={styles.summaryTableLeft}>
              {hasVAT ? (
                <Text
                  style={[styles.textLine, !deferredAmountFormatted ? styles.summaryHighlight : {}]}
                >
                  Gesamt brutto
                </Text>
              ) : (
                <Text
                  style={[styles.textLine, !deferredAmountFormatted ? styles.summaryHighlight : {}]}
                >
                  Gesamt
                </Text>
              )}

              {hasVAT &&
                bill.vat?.map((vg, idx) => (
                  <Text key={idx} style={styles.textLine}>
                    Enthaltene USt.
                  </Text>
                ))}

              {!!deferredAmountFormatted && (
                <>
                  <Text style={[styles.textLine, styles.summaryHighlight]}>
                    Davon sofort zu zahlen
                  </Text>
                  <Text style={styles.textLine}>Davon gestundet</Text>
                </>
              )}
            </View>

            <View style={styles.summaryTableCenter}>
              <Text style={styles.textLine}>&nbsp;</Text>

              {hasVAT &&
                bill.vat?.map((vg, idx) => (
                  <Text style={styles.textLine} key={idx}>
                    {vg.rate}%
                  </Text>
                ))}

              {!!deferredAmountFormatted && (
                <>
                  <Text style={styles.textLine}>&nbsp;</Text>
                  <Text style={styles.textLine}>&nbsp;</Text>
                </>
              )}
            </View>

            <View style={styles.summaryTableRight}>
              <Text style={styles.textLine}>{totalAmountFormatted}</Text>

              {hasVAT &&
                bill.vat?.map((vg, idx) => (
                  <Text style={styles.textLine} key={idx}>
                    {getFormattedAmount(vg.amount, vg.scale)}
                  </Text>
                ))}

              {!!deferredAmountFormatted && (
                <>
                  <Text style={[styles.textLine, styles.summaryHighlight]}>
                    {dueAmountFormatted}
                  </Text>
                  <Text style={styles.textLine}>{deferredAmountFormatted}</Text>
                </>
              )}
            </View>
          </View>
        </View>

        <View style={[styles.section, styles.textBold]}>
          <Text style={{ fontWeight: "bold" }}>{bill_closing_text}</Text>
        </View>
      </View>

      {/* Footer */}
      <View style={styles.footer} wrap={false}>
        <View style={styles.footerLeft}>
          <Text style={styles.textLine}>{bill.issuer_name}</Text>
          <Text style={styles.textLine}>{bill.issuer_address1}</Text>
          <Text style={styles.textLine}>{bill.issuer_address2}</Text>
          <Text style={styles.textLine}>
            {bill.issuer_address_postal_code} {bill.issuer_address_postal_code}
          </Text>
          <Text style={styles.textLine}>{bill.issuer_address_country}</Text>
        </View>
        <View style={styles.footerCenter}>
          {!!bill.issuer_local_court && (
            <Text style={styles.textLine}>{bill.issuer_local_court}</Text>
          )}
          {!!bill.issuer_commercial_register_number && (
            <Text style={styles.textLine}>{bill.issuer_commercial_register_number}</Text>
          )}
          {!!bill.issuer_vat_id && (
            <Text style={styles.textLine}>VAT ID: {bill.issuer_vat_id}</Text>
          )}
          {!!bill.issuer_tax_id && (
            <Text style={styles.textLine}>Steuernummer: {bill.issuer_tax_id}</Text>
          )}
        </View>
        <View style={styles.footerRight}>
          {!!bill.bank_name && <Text style={styles.textLine}>{bill.bank_name}</Text>}
          {!!bill.bank_iban && <Text style={styles.textLine}>IBAN: {bill.bank_iban}</Text>}
          {!!bill.bank_bic && <Text style={styles.textLine}>BIC: {bill.bank_bic}</Text>}
        </View>
      </View>
    </Page>
  )
}
