import { InputProps, SelectInput } from "react-admin"

export const choices = [
  { id: "draft", name: "draft" },
  { id: "documents_sent", name: "documents_sent" },
  { id: "open", name: "open" },
  { id: "past_due", name: "past_due" },
  { id: "paid", name: "paid" },
  { id: "voided", name: "voided" },
  { id: "uncollectible", name: "uncollectible" },
]

export const BillPaymentStatusSelectInput: React.FC<InputProps> = (props) => (
  <SelectInput {...props} choices={choices} />
)
