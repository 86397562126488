import { BillDto } from "../../types/Bill"
import { apiClient } from "./api-client"

export async function fetchPreviewBill(
  property_id: string,
  customer_id: string,
  check_in_day: string,
  check_out_day: string,
  num_adults: number,
  num_children: number,
  num_infants: number,
  has_pets: boolean,
  local_tourism_tax_total_count: number,
): Promise<BillDto> {
  const query = new URLSearchParams()

  query.append("property_id", property_id)
  query.append("customer_id", customer_id)
  query.append("check_in_day", check_in_day)
  query.append("check_out_day", check_out_day)
  query.append("num_adults", num_adults ? num_adults.toString() : "0")
  query.append("num_children", num_children ? num_children.toString() : "0")
  query.append("num_infants", num_infants ? num_infants.toString() : "0")
  query.append("has_pets", has_pets ? "true" : "false")
  query.append(
    "local_tourism_tax_total_count",
    local_tourism_tax_total_count ? local_tourism_tax_total_count.toString() : "0",
  )

  const res = await apiClient.get<BillDto>(`/v1/bills/booking_previews?${query.toString()}`)

  if (!res?.data) {
    throw Error("Cannot create preview bill")
  }

  return res.data
}

// export async function queryProducts(): Promise<IProduct[]> {
//   return apiClient.get<{ data: IProduct[] }>("/v1/products").then((res) => res.data?.data)
// }

// export async function createProduct(data: Partial<IProduct>): Promise<void> {
//   await apiClient.post(`/v1/products`, data)
// }

// export async function fetchProductById(id: string): Promise<IProduct> {
//   return apiClient.get<IProduct>(`/v1/products/${id}`).then((res) => res.data)
// }

// export async function updateProduct(id: string, data: Partial<IProduct>): Promise<void> {
//   await apiClient.patch(`/v1/products/${id}`, data)
// }

// ---------
