import { Typography } from "@mui/material"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import { PDFViewer } from "@react-pdf/renderer"
import { Title } from "react-admin"

import { PDFDoc } from "../../components/PDFDoc"

export const Dashboard = () => (
  <Card sx={{ mt: 2 }}>
    <Title title="" />
    <Typography variant="h5" component="div">
      MYNE Admin
    </Typography>
    <CardContent>...</CardContent>
  </Card>
)
