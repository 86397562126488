import { Alert } from "@mui/material"
import { FilterButton, RedirectionSideEffect, TopToolbar, useRecordContext } from "react-admin"
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  BooleanField,
  List,
  ReferenceManyField,
  Show,
  TabbedShowLayout,
  NumberField,
  Tab,
  TextField,
  BooleanInput,
  EditButton,
  ShowButton,
} from "react-admin"

import CentAsEuroField from "../../../fields/CentAsEuroField"
import { PropertyRefField, PropertyRefInput } from "../../real_estate/properties"
import { CategoryRefField } from "../product_categories"
import { AddPriceButton } from "./AddPriceButton"
import { ProductForm } from "./ProductForm"
import { ProductRefField } from "./ProductRefField"

const ListActions = () => (
  <TopToolbar>
    <FilterButton />
    {/* <CreateButton/> */}
  </TopToolbar>
)

const listFilters = [
  <PropertyRefInput source="property_id" alwaysOn />,
  <BooleanInput source="is_published" />,
]

export const ProductList = () => (
  <List filters={listFilters} actions={<ListActions />} exporter={false}>
    <Datagrid bulkActionButtons={false} rowClick="show">
      <PropertyRefField source="property_id" />
      <CategoryRefField source="category_id" />
      <BooleanField source="is_published" />
      <TextField source="product_code" />
      <TextField source="title" />
      <TextField source="pricing_model" />
      <TextField source="unit" />
      <DateField source="created_at" showTime />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
)

const ProductTitle = () => {
  const record = useRecordContext()

  return <span>{record ? record.title : ""} [Product]</span>
}

export const ProductShow = () => (
  <Show title={<ProductTitle />}>
    <TabbedShowLayout spacing={2}>
      <Tab label="Product Details">
        <CategoryRefField source="category_id" />
        <PropertyRefField source="property_id" />
        <BooleanField source="is_published" />
        <TextField source="product_code" />
        <TextField source="title" />
        {/* <TextField source="title_de" /> */}
        <TextField source="description" />
        {/* <TextField source="description_de" /> */}
        <TextField source="pricing_model" />
        <TextField source="unit" />
        <BooleanField source="guest_count_includes_adults" />
        <BooleanField source="guest_count_includes_children" />
        <BooleanField source="guest_count_includes_infants" />
      </Tab>

      <Tab label="Prices" path="prices">
        <AddPriceButton />
        <ReferenceManyField
          reference="product_prices"
          target="product_id"
          sort={{ field: "valid_from", order: "DESC" }}
          label={false}
        >
          <Datagrid
            bulkActionButtons={false}
            rowClick="show"
            empty={<Alert severity="info">This product has no prices yet.</Alert>}
          >
            <ProductRefField source="product_id" />
            <TextField source="title" />
            <NumberField source="precedence" />
            <DateField source="valid_from" />
            <DateField source="valid_to" />
            <CentAsEuroField source="amount" />
            <DateField source="created_at" showTime />
            <ShowButton />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>

      <Tab label="Meta">
        <TextField source="id" />
        <DateField source="created_at" showTime />
        <DateField source="updated_at" showTime />
        <TextField source="created_by" />
        <NumberField source="version" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

const redirect: RedirectionSideEffect = (resource, id, data) =>
  data ? `product_categories/${data.category_id}/show/products` : "show"

export const ProductCreate = () => (
  <Create redirect={redirect}>
    <ProductForm isExisting={false} />
  </Create>
)

export const ProductEdit = () => (
  <Edit mutationMode="pessimistic" redirect={redirect}>
    <ProductForm isExisting />
  </Edit>
)
