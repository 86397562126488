import { InfoOutlined } from "@mui/icons-material"
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked"
import {
  Chip,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid"
import { toInteger, toString } from "lodash"
import moment from "moment"
import React from "react"
import { Loading, useGetList, useShowContext } from "react-admin"
import { calculateNightsBetween } from "resources/scheduling/utils/calculate-nights-between"
import { calculateTotalNightsInYear } from "resources/scheduling/utils/calculate-total-nights-in-year"

import { ICalendarEntry } from "../../../../types/ICalendarEntry"
import { formatDate } from "../../../../utils/format-date"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  icon: {
    marginRight: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    marginBottom: -theme.spacing(0.5),
  },
}))

const columns: GridColDef[] = [
  // { field: "id", headerName: "ID" }, // , width: 70
  { field: "current_status", headerName: "Status" },
  {
    field: "start_date",
    headerName: "Check-In",
    valueFormatter: (params) => formatDate(params.value),
  },
  {
    field: "end_date",
    headerName: "Check-Out",
    valueFormatter: (params) => formatDate(params.value),
  },
  { field: "Nights", headerName: "Title" },
  { field: "title", headerName: "Title" },
  { field: "internal_notes", headerName: "Int. Notes" },
]

export const ShowRelatedBookings = () => {
  const currentYear = moment().year()
  const { record } = useShowContext<ICalendarEntry>()
  const [year, setYear] = React.useState(currentYear)
  const [totalNightsInYear, setTotalNightsInYear] = React.useState<number | undefined>()
  const [totalConfirmedNightsInYear, setTotalConfirmedNightsInYear] = React.useState<
    number | undefined
  >()
  const [totalRequestedNightsInYear, setTotalRequestedNightsInYear] = React.useState<
    number | undefined
  >()
  const classes = useStyles()

  const { data, isLoading, error } = useGetList<ICalendarEntry>("calendar_entries", {
    pagination: { page: 1, perPage: 50 },
    sort: { field: "start_date", order: "DESC" },
    filter: {
      type: "booking",
      customer_id: record?.customer_id,
      end_date__gte: moment(`01-01-${year}`).startOf("year").toISOString(),
      start_date__lte: moment(`01-01-${year}`).endOf("year").toISOString(),
    },
  })

  React.useEffect(() => {
    if (year && data) {
      setTotalNightsInYear(calculateTotalNightsInYear(data, year))
      setTotalConfirmedNightsInYear(calculateTotalNightsInYear(data, year, ["confirmed"]))
      setTotalRequestedNightsInYear(calculateTotalNightsInYear(data, year, ["requested"]))
    }
  }, [year, data])

  const handleYearChange = (event: React.MouseEvent<HTMLElement>, newYear: string | null) => {
    setYear(toInteger(newYear))
  }

  // record?.start_date
  // record?.end_date

  if (isLoading) return <Loading />
  if (error) return <p>ERROR</p>

  return (
    <div>
      <Stack spacing={2}>
        <ToggleButtonGroup color="primary" value={year} exclusive onChange={handleYearChange}>
          <ToggleButton value={currentYear - 2}>{currentYear - 2}</ToggleButton>
          <ToggleButton value={currentYear - 1}>{currentYear - 1}</ToggleButton>
          <ToggleButton value={currentYear}>{currentYear}</ToggleButton>
          <ToggleButton value={currentYear + 1}>{currentYear + 1}</ToggleButton>
          <ToggleButton value={currentYear + 2}>{currentYear + 2}</ToggleButton>
        </ToggleButtonGroup>

        <div>
          <Typography variant="subtitle2">
            Total nights in {year} (excl. declined or cancelled):
          </Typography>
          <b>{totalNightsInYear}</b>
        </div>
        <div>
          <Typography variant="subtitle2">Total confirmed nights in {year}:</Typography>
          <b>{totalConfirmedNightsInYear}</b>
        </div>
        <div>
          <Typography variant="subtitle2">Total requested nights in {year}:</Typography>
          <b>{totalRequestedNightsInYear}</b>
        </div>
        <div>
          <Typography variant="subtitle2">Confirmed stays in {year}:</Typography>
          <b>{data?.filter((entry) => entry.current_status === "confirmed").length}</b>
        </div>
        <Stack
          spacing={2}
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        ></Stack>

        <TableContainer component={Paper}>
          <Table
            // sx={{ minWidth: 650 }}
            aria-label="simple table"
          >
            <caption>All bookings of owner with start or end date in {year}</caption>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography>Status</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Check-In</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Check-Out</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Title</Typography>
                </TableCell>
                <TableCell>
                  <Tooltip title="[adults]-[children]-[infants] / x = not set">
                    <Typography>
                      Guests <InfoOutlined fontSize="small" />
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  <Tooltip title={`Only nights in selected year (${year}) are counted`}>
                    <Typography>
                      Nights <InfoOutlined fontSize="small" />
                    </Typography>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(data) &&
                data.map((row) => {
                  const totalNights = calculateNightsBetween(row.start_date, row.end_date)
                  const totalNightsInYear = calculateTotalNightsInYear([row], year)
                  const status = row.current_status
                  let color = "black"

                  switch (status) {
                    case "requested":
                      color = "#2196f3"
                      break
                    case "tentative":
                      color = ""
                      break
                    case "confirmed":
                      color = "green"
                      break
                    case "declined":
                      color = "red"
                      break
                    case "cancelled":
                      color = "red"
                      break

                    default:
                      break
                  }

                  return (
                    <TableRow key={row.id}>
                      <TableCell>
                        {/* <Chip variant="outlined" size="small" label={row.current_status} /> */}
                        <div className={classes.root}>
                          {status && (
                            <RadioButtonCheckedIcon
                              className={classes.icon}
                              style={{ color: color }}
                            />
                          )}
                          {status}
                        </div>
                      </TableCell>
                      <TableCell>{formatDate(row.start_date)}</TableCell>
                      <TableCell>{formatDate(row.end_date)}</TableCell>
                      <TableCell>{row.title}</TableCell>
                      <TableCell>
                        {row.adults_count ?? "x"}-{row.children_count ?? "x"}-
                        {row.infants_count ?? "x"}
                      </TableCell>
                      <TableCell align="right">
                        {totalNightsInYear} ({totalNights})
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </div>
  )
}
