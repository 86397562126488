import { Stack, Typography } from "@mui/material"
import {
  BooleanInput,
  DateInput,
  NumberInput,
  SimpleFormProps,
  TextInput,
  Labeled,
  DateTimeInput,
  FormDataConsumer,
  TextField,
  required,
  SimpleForm,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from "react-admin"

import { UserRefField, UserRefInput } from "../../iam/users"
import { OwnerRefField, OwnerRefInput } from "../../real_estate/owners"
import { CalendarRefField } from "../calendars"
import { CalendarRefInput } from "../calendars/CalendarRefInput"
import { QuotaRefField, QuotaRefInput } from "../quotas"
import { SelectEntryTypeInput } from "./inputs/SelectEntryTypeInput"
import { SelectStatusInput } from "./inputs/SelectStatusInput"

type Props = Omit<Omit<SimpleFormProps, "children">, "render"> & {
  isExisting: boolean
}

const typesWithTimeOption = ["other"]

export const CalendarEntryForm = ({ isExisting = false, ...props }: Props) => {
  return (
    <SimpleForm>
      {isExisting && (
        <Stack direction="row" spacing={2} alignItems="baseline">
          <Labeled source="type" fullWidth>
            <TextField fullWidth source="type" />
          </Labeled>
          <Labeled source="calendar_id" fullWidth>
            <CalendarRefField fullWidth source="calendar_id" />
          </Labeled>
          <Labeled source="customer_id" fullWidth>
            <OwnerRefField fullWidth source="customer_id" label="Owner" />
          </Labeled>
          <Labeled source="quota_id" fullWidth>
            <QuotaRefField fullWidth source="quota_id" label="Quota" />
          </Labeled>
          <Labeled source="user_id" fullWidth>
            <UserRefField fullWidth source="user_id" />
          </Labeled>
        </Stack>
      )}

      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <>
            <Typography variant="h6" gutterBottom>
              General
            </Typography>
            <Stack direction="row" spacing={2} alignItems="baseline">
              {!isExisting && <SelectEntryTypeInput source="type" isRequired />}

              {formData.type === "booking" && (
                <SelectStatusInput source="current_status" validate={[required()]} />
              )}
            </Stack>

            <TextInput fullWidth source="title" isRequired />

            <ReferenceArrayInput label="Tags" reference="tags" source="tag_ids">
              <AutocompleteArrayInput optionText="label" />
            </ReferenceArrayInput>

            {!isExisting && (
              <>
                <Typography variant="h6" gutterBottom>
                  Relations
                </Typography>

                <CalendarRefInput fullWidth source="calendar_id" validate={[required()]} />

                {["booking"].includes(formData.type) && (
                  <>
                    <UserRefInput fullWidth source="user_id" />
                    <OwnerRefInput fullWidth source="customer_id" />
                  </>
                )}
              </>
            )}

            <Typography variant="h6" gutterBottom>
              Date & Time
            </Typography>

            {typesWithTimeOption.includes(formData.type) && (
              <BooleanInput fullWidth source="is_all_day" disabled={isExisting} />
            )}

            <Stack direction="row" spacing={2} alignItems="baseline">
              {formData.is_all_day === true || !typesWithTimeOption.includes(formData.type) ? (
                <DateInput
                  fullWidth
                  source="start_date"
                  helperText="Check-in date for bookings (local time)"
                  validate={[required()]}
                />
              ) : (
                <DateTimeInput
                  fullWidth
                  source="starts_at"
                  helperText="Check-in date for bookings (local time)"
                  validate={[required()]}
                />
              )}

              {formData.is_all_day === true || !typesWithTimeOption.includes(formData.type) ? (
                <DateInput
                  fullWidth
                  source="end_date"
                  helperText="Check-out date for bookings (local time)"
                  validate={[required()]}
                />
              ) : (
                <DateTimeInput
                  fullWidth
                  source="ends_at"
                  helperText="Check-out date for bookings (local time)"
                  validate={[required()]}
                />
              )}
            </Stack>

            {formData.type === "booking" && (
              <>
                <Typography variant="h6" gutterBottom>
                  Booking Details
                </Typography>

                <QuotaRefInput fullWidth source="quota_id" />

                <Stack direction="row" spacing={2} alignItems="baseline">
                  <NumberInput fullWidth source="adults_count" helperText="Ages 13 or above" />
                  <NumberInput fullWidth source="children_count" helperText="Ages 4-12" />
                  <NumberInput fullWidth source="infants_count" helperText="Under age of 4" />
                  <BooleanInput fullWidth source="has_pets" />
                </Stack>

                <NumberInput
                  source="local_tourism_tax_total_count"
                  helperText="Number of guests x number of nights"
                />

                <TextInput
                  multiline
                  rows={3}
                  fullWidth
                  source="note"
                  label="Additional Notes"
                  helperText="Special wishes, comments etc."
                />

                <Typography variant="h6">Contact Details</Typography>
                <Typography variant="caption" gutterBottom>
                  If User is not joining the stay
                </Typography>
                <Stack direction="row" spacing={2} alignItems="baseline">
                  <TextInput fullWidth source="contact_name" />
                  <TextInput fullWidth source="contact_phone" />
                  <TextInput fullWidth source="contact_email" />
                </Stack>
              </>
            )}

            <Typography variant="h6" gutterBottom>
              Internal
            </Typography>
            <TextInput source="internal_notes" fullWidth multiline rows={3} />
          </>
        )}
      </FormDataConsumer>
    </SimpleForm>
  )
}
