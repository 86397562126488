import { Alert } from "@mui/material"
import {
  BooleanField,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  ImageField,
  List,
  NumberField,
  ReferenceManyField,
  SelectInput,
  Show,
  ShowButton,
  Tab,
  TabbedShowLayout,
  TextField,
} from "react-admin"

import { OwnerRefField } from "../owners"
import { OwnedSharesField } from "../ownerships/OwnedSharesField"
import { AddBillingIssuerProfileButton } from "./AddBillingIssuerProfileButton"
import { AddOwnershipButton } from "./AddOwnershipButton"
import { AddPropertyProfileButton } from "./AddPropertyProfileButton"
import { PropertyEditAside } from "./PropertyEditAside"
import { PropertyForm } from "./PropertyForm"
import { PropertyTitle } from "./PropertyTitle"

const listFilters = [
  <SelectInput
    source="country"
    alwaysOn
    choices={[
      { id: "at", name: "Austria" },
      { id: "de", name: "Germany" },
      { id: "es", name: "Spain" },
    ]}
  />,
]

export const PropertyList = () => (
  <List
    exporter={false}
    // filters={listFilters}
    sort={{ field: "short_id", order: "DESC" }}
  >
    <Datagrid bulkActionButtons={false} rowClick="show">
      <TextField source="short_id" label="Short ID" />
      <TextField source="name" />
      <TextField source="address_city" label="City" />
      <TextField source="address_country" label="Country" />
      <DateField source="created_at" showTime />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
)

export const PropertyShow = () => (
  <Show title={<PropertyTitle />}>
    <TabbedShowLayout spacing={2}>
      <Tab label="Property Details">
        <TextField label="Short ID" source="short_id" />
        <TextField source="name" />
        <TextField source="description" />
        <TextField source="info_text" />
        <TextField source="timezone" />
      </Tab>

      <Tab label="Address">
        <TextField source="address_field_1" />
        <TextField source="address_field_2" />
        <TextField source="address_postal_code" />
        <TextField source="address_city" />
        <TextField source="address_country" />
        <TextField source="address_lat" label="Latitude" />
        <TextField source="address_long" label="Longitude" />
      </Tab>

      <Tab label="Billing Profiles" path="billing_issuer_profiles">
        <AddBillingIssuerProfileButton />
        <ReferenceManyField
          reference="billing_issuer_profiles"
          target="property_id"
          sort={{ field: "created_at", order: "ASC" }}
          label={false}
        >
          <Datagrid
            rowClick="show"
            bulkActionButtons={false}
            empty={
              <Alert severity="info">No billing issuer profiles existing for this property.</Alert>
            }
          >
            <TextField source="booking_type" />
            <TextField source="profile_name" />
            <TextField source="bank_account_owner" />
            <TextField source="bank_name" />
            <TextField source="default_bill_property_label" />
            <TextField source="default_bill_due_days" />
            <ShowButton />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>

      <Tab label="Images">
        <ImageField source="card_image_url" sx={{ "& .RaImageField-image": { maxWidth: 400 } }} />
        <ImageField source="header_image_url" sx={{ "& .RaImageField-image": { maxWidth: 400 } }} />
        <ImageField source="thumb_image_url" sx={{ "& .RaImageField-image": { maxWidth: 400 } }} />
      </Tab>

      <Tab label="Profiles" path="profiles">
        <AddPropertyProfileButton />
        <ReferenceManyField
          reference="property_profiles"
          target="property_id"
          sort={{ field: "created_at", order: "ASC" }}
          label={false}
        >
          <Datagrid
            bulkActionButtons={false}
            empty={<Alert severity="info">No profiles existing for this property.</Alert>}
          >
            <TextField source="checkinout_description" />
            <NumberField source="max_adults_count" />
            <NumberField source="max_children_count" />
            <NumberField source="max_infants_count" />
            <DateField source="valid_from" />
            <DateField source="valid_until" />
            <ShowButton />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>

      <Tab label="Owners" path="ownerships">
        <AddOwnershipButton />
        <ReferenceManyField
          reference="ownerships"
          target="property_id"
          sort={{ field: "created_at", order: "ASC" }}
          label={false}
        >
          <Datagrid
            bulkActionButtons={false}
            empty={<Alert severity="info">No owners existing for this property.</Alert>}
          >
            <OwnerRefField source="customer_id" />
            <OwnedSharesField source="owned_shares" />
            <BooleanField source="is_disabled" />
            <TextField source="internal_notes" />
            <DateField source="created_at" showTime />
            <ShowButton />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>

      {/*
      <Tab label="Shares" path="shares">
        <AddShareButton />
        <ReferenceManyField
          reference="property_shares"
          target="property_id"
          sort={{ field: "share_no", order: "ASC" }}
          label={false}
        >
          <Datagrid
            bulkActionButtons={false}
            empty={<Alert severity="info">No shares existing for this property.</Alert>}
          >
            <NumberField source="share_no" />
            <TextField source="display_name" />
            <DateField source="created_at" showTime />
            <ShowButton />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      */}

      <Tab label="Meta">
        <TextField source="id" />
        <DateField showTime source="created_at" />
        <DateField showTime source="updated_at" />
        <TextField source="created_by" />
        <NumberField source="version" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export const PropertyCreate = () => (
  <Create redirect="show">
    <PropertyForm isExisting={false} defaultValues={{ total_shares_count: 8 }} />
  </Create>
)

export const PropertyEdit = () => (
  <Edit
    mutationMode="pessimistic"
    // aside={<PropertyEditAside />}
  >
    <PropertyForm isExisting />
  </Edit>
)
