import React from "react"
import { SimpleForm, SimpleFormProps, TextInput, required } from "react-admin"

import { UserRefInput } from "../../iam/users"
import { OwnerRefInput } from "../owners"
import { SelectMembershipRoleInput } from "./inputs/SelectMembershipRoleInput"

type Props = Partial<SimpleFormProps> & {
  isExisting: boolean
}

export const MemberForm: React.FC<Props> = ({ isExisting = false, ...rest }) => (
  <SimpleForm {...rest}>
    {isExisting ? <TextInput source="id" disabled /> : null}

    <OwnerRefInput source="customer_id" validate={[required()]} />
    <UserRefInput source="user_id" validate={[required()]} />

    <SelectMembershipRoleInput source="role" validate={[required()]} />

    <TextInput source="internal_notes" multiline rows={3} />
  </SimpleForm>
)
