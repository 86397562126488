import { get } from "lodash"
import { parse } from "query-string"
import { RedirectionSideEffect, useEditController } from "react-admin"
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  Filter,
  List,
  ListProps,
  ShowGuesser,
  TextField,
} from "react-admin"

import { BillRefField } from "../bills"
import { BillStatusForm } from "./BillStatusForm"

const BillStatusFilter = (props: any) => {
  return (
    <Filter {...props}>
      <BillRefField />
    </Filter>
  )
}

export const BillStatusList = (props: ListProps) => (
  <List {...props} filters={<BillStatusFilter />}>
    <Datagrid rowClick="show">
      <BillRefField source="bill_id" />

      <TextField source="status" />
      <TextField source="internal_notes" />

      <DateField source="created_at" showTime />
    </Datagrid>
  </List>
)

export const BillStatusShow = ShowGuesser
// export const BillStatusShow = (props: ShowProps) => (
//   <Show {...props}>
//     <SimpleShowLayout>
//       <TextField source="id" />
//       <CalendarRefField />
//     </SimpleShowLayout>
//   </Show>
// )

const redirect: RedirectionSideEffect = (resource, id, data) =>
  data ? (data.bill_id ? `bills/${data.bill_id}/show/statuses` : "edit") : "show"

export const BillStatusCreate = () => (
  <Create redirect={redirect}>
    <BillStatusForm isExisting={false} />
  </Create>
)

export const BillStatusEdit = () => (
  <Edit mutationMode="pessimistic" redirect={redirect}>
    <BillStatusForm isExisting />
  </Edit>
)
