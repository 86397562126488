import {
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  Pagination,
  PaginationProps,
  ShowButton,
  TextField,
  TextInput,
} from "react-admin"

const PostFilters = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="label" alwaysOn />
    </Filter>
  )
}

const PostPagination = (props: PaginationProps) => (
  <Pagination rowsPerPageOptions={[10, 25, 50]} {...props} />
)

export const TagsList = () => (
  <List
    filters={<PostFilters />}
    exporter={false}
    sort={{ field: "label", order: "ASC" }}
    pagination={<PostPagination />}
  >
    <Datagrid bulkActionButtons={false} rowClick="show">
      <TextField source="label" />
      <TextField source="color" />
      <DateField source="created_at" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
)
