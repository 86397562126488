import { PDFViewer } from "@react-pdf/renderer"
import { useGetOne, useRecordContext } from "react-admin"

import { ICalendarEntry } from "@/types/ICalendarEntry"

import { BookingBillPdfDoc } from "../../../components/BookingBillPdfDoc"
import { BillDto } from "../../../types/Bill"
import { ICalendar } from "../../../types/ICalendar"
import { IProperty } from "../../../types/IProperty"

export const BillPdfField = () => {
  const record = useRecordContext<BillDto>()
  const { data: booking } = useGetOne<ICalendarEntry>("calendar_entries", { id: record.booking_id })
  const { data: calendar } = useGetOne<ICalendar>(
    "calendars",
    { id: booking?.calendar_id ?? "" },
    { enabled: !!booking?.calendar_id },
  )
  const { data: property } = useGetOne<IProperty>(
    "properties",
    { id: calendar?.property_id ?? "" },
    { enabled: !!calendar?.property_id },
  )

  return (
    <span>
      {record && (
        <PDFViewer showToolbar width={1024} height={1200}>
          <BookingBillPdfDoc bill={record} booking={booking} property={property} />
        </PDFViewer>
      )}
    </span>
  )
}
