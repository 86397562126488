import React from "react"
import { InputProps, ReferenceInput, SelectInput, ReferenceInputProps } from "react-admin"

interface Props extends Omit<InputProps, "source"> {
  source?: string
}

const OwnerRefInput: React.FC<
  Props & Omit<Omit<ReferenceInputProps, "source">, "reference" | "children">
> = (props) => (
  <ReferenceInput
    source="customer_id"
    reference="customers"
    perPage={100}
    sort={{
      field: "last_name",
      order: "ASC",
    }}
  >
    <SelectInput optionText="display_name" {...props} label="Owner" />
  </ReferenceInput>
)

OwnerRefInput.defaultProps = {
  source: "customer_id",
}

export { OwnerRefInput }
