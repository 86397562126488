import HelpIcon from "@mui/icons-material/Help"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import React from "react"
import { useUpdate, useNotify, useRedirect, Button, Confirm, useRecordContext } from "react-admin"

const RESOURCE = "calendar_entries"

const RequestButton = () => {
  const record = useRecordContext()
  const notify = useNotify()
  const redirectTo = useRedirect()
  const [open, setOpen] = React.useState(false)

  const [approve, { isLoading }] = useUpdate(
    RESOURCE,
    {
      id: record?.id,
      data: { current_status: "requested" },
      previousData: record,
    },
    // {
    //   undoable: true,
    //   onSuccess: () => {
    //     notify("Requested", "info", {}, true)
    //     redirectTo("/calendar_entries/" + record.id)
    //   },
    //   onFailure: () => {
    //     notify("Error declining", "warning")
    //   },
    // },
  )

  if (!record) return null

  const handleClick = () => setOpen(true)
  const handleDialogClose = () => setOpen(false)
  const handleConfirm = () => {
    approve()
    setOpen(false)
  }

  return record && isEmpty(record.current_status) ? (
    <>
      <Button
        // variant="outlined" color="secondary" size="small"
        label="Reset to Requested"
        startIcon={<HelpIcon color="primary" style={{ paddingRight: "0.5em", color: "blue" }} />}
        onClick={handleClick}
        disabled={isLoading}
      />
      <Confirm
        isOpen={open}
        loading={isLoading}
        title="Reset Status to Requested"
        content="Are you sure you want to reset the status of the booking to to requested?"
        cancel="No"
        confirm="Yes"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  ) : (
    <span />
  )
}

RequestButton.propTypes = {
  record: PropTypes.any,
}

export default RequestButton
