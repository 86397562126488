import {
  Create,
  Datagrid,
  DateField,
  SimpleShowLayout,
  Edit,
  Filter,
  BooleanField,
  List,
  Show,
  NumberField,
  TextField,
  BooleanInput,
  RedirectionSideEffect,
  ShowButton,
  EditButton,
  TabbedShowLayout,
  Tab,
} from "react-admin"

import { OwnerRefField, OwnerRefInput } from "../../real_estate/owners"
import { PropertyRefField, PropertyRefInput } from "../../real_estate/properties"
import { QuotaForm } from "./QuotaForm"

const quotaFilters = [
  <PropertyRefInput source="property_id" />,
  <OwnerRefInput source="customer_id" />,
  <BooleanInput source="is_disabled" />,
]

export const QuotaList = () => (
  <List filters={quotaFilters} exporter={false} sort={{ field: "created_at", order: "DESC" }}>
    <Datagrid bulkActionButtons={false} rowClick="show">
      <PropertyRefField source="property_id" />
      <OwnerRefField source="customer_id" />
      <TextField source="title" />
      <NumberField source="share_count" />
      <DateField source="created_at" showTime />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
)

export const QuotaShow = () => (
  <Show>
    <TabbedShowLayout spacing={2}>
      <Tab label="Quota Details">
        <PropertyRefField source="property_id" />
        <OwnerRefField source="customer_id" />
        <TextField source="title" />
        <DateField source="valid_from" />
        <BooleanField source="is_disabled" />
        <TextField source="internal_notes" />
      </Tab>

      <Tab label="Quota Usage">
        <div>manually check usage of quota per period (year)</div>
      </Tab>

      <Tab label="Meta">
        <TextField source="id" />
        <DateField source="created_at" showTime />
        <DateField source="updated_at" showTime />
        <TextField source="created_by" />
        <NumberField source="version" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

const redirect: RedirectionSideEffect = (resource, id, data) =>
  data ? `customers/${data.customer_id}/show/quotas` : "show"

export const QuotaCreate = () => (
  <Create redirect={redirect}>
    <QuotaForm isExisting={false} />
  </Create>
)

export const QuotaEdit = () => (
  <Edit mutationMode="pessimistic" redirect={redirect}>
    <QuotaForm isExisting />
  </Edit>
)
