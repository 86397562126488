import { Theme } from "@mui/material/styles"
import React from "react"
import { Admin, AuthProvider, Resource } from "react-admin"
import guestTemplateResource from "resources/real_estate/guest_templates"
import tagsResource from "resources/tag_management/tags"

import appConfig from "../config/app.config"
import firebaseConfig from "../config/firebase.config"
import { i18nProvider } from "../i18n"
import billGroupsResource from "../resources/billing/bill_groups"
import billLineItemsResource from "../resources/billing/bill_line_items"
import billPaymentStatusesResource from "../resources/billing/bill_payment_statuses"
import billStatusesResource from "../resources/billing/bill_statuses"
import billingIssuerProfilesResource from "../resources/billing/billing_issuer_profiles"
import billingRecipientProfilesResource from "../resources/billing/billing_recipient_profiles"
import billsResource from "../resources/billing/bills"
import productCategoriesResource from "../resources/billing/product_categories"
import productPricesResource from "../resources/billing/product_prices"
import productsResource from "../resources/billing/products"
import userResource from "../resources/iam/users"
import customerMembershipsResource from "../resources/real_estate/customer_memberships"
import ownersResource from "../resources/real_estate/owners"
import ownershipsResource from "../resources/real_estate/ownerships"
import propertiesResource from "../resources/real_estate/properties"
import propertySharesResource from "../resources/real_estate/shares"
import calendarEntriesResource from "../resources/scheduling/calendar_entries"
import calendarEntryGuestsResource from "../resources/scheduling/calendar_entry_guests"
import calendarMembersResource from "../resources/scheduling/calendar_members"
import calendarsResource from "../resources/scheduling/calendars"
import entryStatusesResource from "../resources/scheduling/entry_statuses"
import propertyProfilesResource from "../resources/scheduling/property_profiles"
import quotasResource from "../resources/scheduling/quotas"
import { CustomLayout } from "./CustomLayout"
import { Dashboard } from "./Dashboard/Dashboard"
import { makeHttpClient, dataProviderFactory, authProviderFactory, AuthOptions } from "./providers"

declare module "@mui/private-theming" {
  interface DefaultTheme extends Theme {}
}

const options: AuthOptions = {
  logging: true,
  persistence: "local",
}

const authProvider: AuthProvider = {
  ...authProviderFactory(firebaseConfig.config, options),
  checkError: (error) => {
    const status = error.status

    console.log("[AUTH] checkError, status =", status)

    // if (status === 401 || status === 403) {
    //   localStorage.removeItem("auth")
    //   return Promise.reject({ redirectTo: "/credentials-required" })
    // }
    // other error code (404, 500, etc): no need to log out

    return Promise.resolve()
  },
}
const httpClient = makeHttpClient(authProvider)
// TODO: Remove v1
const dataProvider = dataProviderFactory(
  appConfig.apiBaseUrl + "/v1",
  appConfig.leadApiBaseUrl,
  httpClient,
)

export const App: React.FC = () => (
  <Admin
    dataProvider={dataProvider}
    authProvider={authProvider}
    i18nProvider={i18nProvider}
    // customReducers={{ theme: themeReducer }}
    // customRoutes={routes}
    layout={CustomLayout}
    dashboard={Dashboard}
    disableTelemetry
  >
    {/*
    <CustomRoutes noLayout>
      <Route path="/configuration" element={<ConfigurationPage />} />
    </CustomRoutes>
    */}

    {/* Scheduling */}
    <Resource
      {...calendarsResource}
      name="calendars"
      options={{ label: "Calendars", group: "Scheduling" }}
    />
    <Resource
      {...calendarMembersResource}
      name="calendar_members"
      options={{ label: "Calendar Member", group: "Scheduling" }}
    />
    <Resource
      {...calendarEntriesResource}
      name="calendar_entries"
      options={{ label: "Events", group: "Scheduling" }}
    />
    <Resource
      {...calendarEntryGuestsResource}
      name="calendar_entry_guests"
      options={{ label: "Calendar Entry Guests", group: "Scheduling" }}
    />
    <Resource
      {...entryStatusesResource}
      name="calendar_entry_statuses"
      options={{ label: "Event Statuses", group: "Scheduling" }}
    />
    <Resource
      {...quotasResource}
      name="booking_quotas"
      options={{ label: "Quotas", group: "Scheduling" }}
    />
    <Resource
      {...propertyProfilesResource}
      name="property_profiles"
      options={{ label: "Property Profiles", group: "Scheduling" }}
    />

    {/* RealEstate */}
    <Resource
      {...ownersResource}
      name="customers"
      options={{ label: "Owners", group: "Real Estate" }}
    />
    <Resource
      {...ownershipsResource}
      name="ownerships"
      options={{ label: "Ownerships", group: "Real Estate" }}
    />
    <Resource
      {...customerMembershipsResource}
      name="customer_memberships"
      options={{ label: "Customer Memberships", group: "Real Estate" }}
    />
    <Resource
      {...propertiesResource}
      name="properties"
      options={{ label: "Properties", group: "Real Estate" }}
    />
    <Resource
      {...propertySharesResource}
      name="property_shares"
      options={{ label: "Property Shares", group: "Real Estate" }}
    />
    <Resource
      {...guestTemplateResource}
      name="guest_templates"
      options={{ label: "Guest templates", group: "Real Estate" }}
    />

    {/* Billing */}
    <Resource
      {...productsResource}
      name="products"
      options={{ label: "Products", group: "Billing" }}
    />
    <Resource
      {...productCategoriesResource}
      name="product_categories"
      options={{ label: "Product Categories", group: "Billing" }}
    />
    <Resource
      {...productPricesResource}
      name="product_prices"
      options={{ label: "Product Prices", group: "Billing" }}
    />

    <Resource {...billsResource} name="bills" options={{ label: "Bills", group: "Billing" }} />
    <Resource
      {...billStatusesResource}
      name="bill_statuses"
      options={{ label: "Bill Status", group: "Billing" }}
    />
    <Resource
      {...billPaymentStatusesResource}
      name="bill_payment_statuses"
      options={{ label: "Bill Payment Status", group: "Billing" }}
    />
    <Resource
      {...billGroupsResource}
      name="bill_groups"
      options={{ label: "Bill Groups", group: "Billing" }}
    />
    <Resource
      {...billLineItemsResource}
      name="bill_line_items"
      options={{ label: "Bill Line Items", group: "Billing" }}
    />
    <Resource
      {...billingIssuerProfilesResource}
      name="billing_issuer_profiles"
      options={{ label: "Billing Issuer Profiles", group: "Billing" }}
    />
    <Resource
      {...billingRecipientProfilesResource}
      name="billing_recipient_profiles"
      options={{ label: "Billing Recipient Profiles", group: "Billing" }}
    />

    {/* Tags */}
    <Resource {...tagsResource} name="tags" options={{ label: "Tags list", group: "tags" }} />

    {/* IaM */}
    <Resource {...userResource} name="users" options={{ label: "Users", group: "IAM" }} />
  </Admin>
)
