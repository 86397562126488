export const SeasonChoices = [
  { id: "low", name: "low" },
  { id: "medium", name: "medium" },
  { id: "high", name: "high" },
  { id: "peak", name: "peak" },
]

export const BookingTypeChoices = [
  { id: "owner", name: "owner" },
  { id: "club", name: "club" },
  { id: "external", name: "external" },
]
