import RescheduleIcon from "@mui/icons-material/EventRounded"
import { Alert, Box, Card, CardContent, Divider, Grid, Stack, Typography } from "@mui/material"
import {
  BooleanField,
  Button,
  ChipField,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  NumberField,
  ReferenceArrayField,
  ReferenceManyField,
  Show,
  ShowButton,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useShowContext,
} from "react-admin"
import { MoneyField } from "resources/billing/bills/MoneyField"
import { OwnerRefField } from "resources/real_estate/owners"

import { UserRefField } from "../../iam/users"
import { GuestAddButton } from "../calendar_entry_guests/GuestAddButton"
import { CalendarRefField } from "../calendars"
import { CalendarReferenceManyField } from "../calendars/CalendarReferenceManyField"
import { QuotaRefField } from "../quotas"
import { AddBillButton } from "./AddBillButton"
import { AddStatusButton } from "./AddStatusButton"
import { CalendarEntryEditAside } from "./CalendarEntryEditAside"
import { CalendarEntryForm } from "./CalendarEntryForm"
import CancelButton from "./buttons/CancelButton"
import ConfirmButton from "./buttons/ConfirmButton"
import DeclineButton from "./buttons/DeclineButton"
import RequestButton from "./buttons/RequestButton"
import { PreviewBill } from "./components/PreviewBill"
import EntryStatusField from "./fields/EntryStatusField"
import { ShowRelatedBookings } from "./fields/ShowRelatedBookings"
import { BookingInfoCard } from "./show/BookingInfoCard"
import { OwnerInfoCard } from "./show/OwnerInfoCard"

const CalendarEntryShowActions = () => {
  const { record } = useShowContext()

  return (
    <TopToolbar>
      {/* <AddStatusButton /> */}
      {/* <Button color="primary" label="Reset Password" startIcon={<LockReset />} onClick={() => {}} /> */}
      <Button
        color="primary"
        label="Reschedule"
        startIcon={<RescheduleIcon />}
        onClick={() => {
          alert("Not Implemented")
        }}
      />

      <ConfirmButton record={record} />
      <CancelButton record={record} />
      <DeclineButton record={record} />
      <RequestButton record={record} />

      <EditButton />
    </TopToolbar>
  )
}

const ShowPreviewBill = () => {
  const { record } = useShowContext()

  return <div>{!!record && <PreviewBill booking={record} />}</div>
}

export const CalendarEntryShow = () => (
  <Show actions={<CalendarEntryShowActions />}>
    <TabbedShowLayout spacing={2}>
      <Tab label="Entry Details">
        <CalendarRefField />
        <UserRefField />
        <TextField source="type" />
        <EntryStatusField source="current_status" />
        <TextField source="title" />
        <ReferenceArrayField label="Tags" reference="tags" source="tag_ids">
          <SingleFieldList>
            <ChipField source="label" />
          </SingleFieldList>
        </ReferenceArrayField>
        <DateField source="start_date" />
        <TextField source="starts_at" />
        <DateField source="end_date" />
        <TextField source="ends_at" />
        <TextField source="internal_notes" />
      </Tab>

      <Tab label="Booking Details">
        <QuotaRefField source="quota_id" />
        <TextField source="title" />
        <DateField source="start_date" label="Check-In date" />
        <DateField source="end_date" label="Check-Out date" />
        <NumberField source="adults_count" />
        <NumberField source="children_count" />
        <NumberField source="infants_count" />
        <TextField source="contact_name" />
        <TextField source="contact_phone" />
        <TextField source="contact_email" />
        <TextField source="note" label="Additional Notes" />
      </Tab>

      <Tab label="Guests" path="guests">
        <GuestAddButton />

        <ReferenceManyField
          reference="calendar_entry_guests"
          source="id"
          target="calendar_entry_id"
          sort={{ field: "created_at", order: "DESC" }}
          label="All Guests"
        >
          <Datagrid
            rowClick="show"
            bulkActionButtons={false}
            empty={<Alert severity="info">No guests for this booking.</Alert>}
          >
            <TextField source="first_name" />
            <TextField source="last_name" />
            <TextField source="id_card_number" />
            <DateField source="birth_date" />
            <DateField source="attended_from" />
            <DateField source="attended_to" />

            <DateField source="created_at" showTime />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>

      <Tab label="Check Dates/Quota">
        <Typography variant="h5" gutterBottom component="div">
          Check Dates
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={7}>
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Property Calendar
                </Typography>

                <CalendarRefField />

                <Box mb={2} />

                <CalendarReferenceManyField label={false} source="calendar_id" />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={5}>
            <BookingInfoCard />

            <Box mb={2} />

            <Card>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Owners' Bookings per Period
                </Typography>
                <ShowRelatedBookings />
              </CardContent>
            </Card>

            <Box mb={2} />

            <OwnerInfoCard />
          </Grid>
        </Grid>
      </Tab>

      <Tab label="Billing" path="billing">
        {/* <ShowPreviewBill /> */}
        <AddBillButton />

        <ReferenceManyField
          reference="bills"
          source="id"
          target="booking_id"
          sort={{ field: "created_at", order: "DESC" }}
          label="All Saved Bills"
        >
          <Datagrid
            rowClick="show"
            bulkActionButtons={false}
            empty={<Alert severity="info">No bills existing for this booking.</Alert>}
          >
            <OwnerRefField source="customer_id" />
            <TextField source="unique_number" />
            <DateField source="check_in_day" />
            <DateField source="check_out_day" />
            <MoneyField source="subtotal" />
            <MoneyField source="vat" />
            <MoneyField source="total" />
            <MoneyField source="due" />
            <DateField source="created_at" showTime />
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>

      <Tab label="Status History" path="statuses">
        <AddStatusButton />
        <ReferenceManyField
          reference="calendar_entry_statuses"
          target="calendar_entry_id"
          sort={{ field: "created_at", order: "DESC" }}
          label={false}
        >
          <Datagrid bulkActionButtons={false}>
            <EntryStatusField source="status" />
            <TextField source="internal_notes" />
            <DateField source="created_at" showTime />
            <UserRefField source="created_by" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>

      <Tab label="Event Settings">
        <DateField source="current_status_at" showTime />
        <TextField source="transparency" />
        <TextField source="participation_status" />
        <TextField source="attendees" />
        <TextField source="organizer" />
        <TextField source="categories" />
        <BooleanField source="is_locked" />
        <TextField source="start_timezone" />
        <TextField source="end_timezone" />
        <BooleanField source="guests_can_invite_others" />
        <BooleanField source="guests_can_see_other_guests" />
        <TextField source="status" />
      </Tab>

      <Tab label="Meta">
        <TextField source="id" />
        <DateField showTime source="created_at" />
        <DateField showTime source="updated_at" />
        <TextField source="created_by" />
        <NumberField source="version" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export const CalendarEntryCreate = () => (
  <Create redirect="show">
    <CalendarEntryForm
      isExisting={false}
      defaultValues={{ calendar_id: undefined, is_all_day: true, timezone: "Europe/Berlin" }}
    />
  </Create>
)

export const CalendarEntryEdit = () => (
  <Edit aside={<CalendarEntryEditAside />} mutationMode="pessimistic" redirect="show">
    <CalendarEntryForm isExisting />
  </Edit>
)
