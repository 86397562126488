import { ReferenceInputProps, ReferenceInput, SelectInput } from "react-admin"

interface Props extends Omit<ReferenceInputProps, "source"> {
  source?: string
}

const CalendarRefInput = (props: Props) => (
  <ReferenceInput source="calendar_id" reference="calendars">
    <SelectInput fullWidth={props.fullWidth} optionText="title" {...props} />
  </ReferenceInput>
)

CalendarRefInput.defaultProps = {
  source: "calendar_id",
}

export { CalendarRefInput }
