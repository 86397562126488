import AccessTimeIcon from "@mui/icons-material/AccessTime"
import {
  Typography,
  Card,
  CardContent,
  Grid,
  Box,
  Link,
  Stepper,
  Step,
  StepLabel,
  StepContent,
} from "@mui/material"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import makeStyles from "@mui/styles/makeStyles"
import { isNumber } from "lodash"
import PropTypes from "prop-types"
import * as React from "react"
import { FC } from "react"
import {
  NumberField,
  TextField,
  DateField,
  useTranslate,
  useGetList,
  RaRecord,
  Identifier,
  ReferenceField,
  useLocale,
  Labeled,
  ReferenceManyField,
  Pagination,
  Datagrid,
  EditButton,
  Button,
  useDataProvider,
  useRecordContext,
} from "react-admin"
import { Link as RouterLink } from "react-router-dom"

import { ICalendarEntry } from "@/types/ICalendarEntry"

import { UserRefField } from "../../iam/users"
import { OwnerRefField } from "../../real_estate/owners"
import { CalendarEntry } from "../types"
import { AddBillButton } from "./AddBillButton"
import StatusQuickCreateButton from "./StatusQuickCreateButton"
import CancelButton from "./buttons/CancelButton"
import ConfirmButton from "./buttons/ConfirmButton"
import DeclineButton from "./buttons/DeclineButton"
import RequestButton from "./buttons/RequestButton"
import { PreviewBill } from "./components/PreviewBill"
import EntryStatusField from "./fields/EntryStatusField"

function createData(pos: string, qty: number, unit: string, sum: number) {
  return { pos, qty, unit, sum }
}

// Nebenkostenpauschale (x * Übernachtung)
// Wäschepaket pro Person (erwachsene + kinder * y)
// Endreinigung (z)
// Tierpauschale (m) (wenn tier ja/nein)

function formatCent(value?: number): string {
  if (!isNumber(value)) return ""

  const eur = value / 100

  return `${eur} €`
}

function formatPercentage(value?: number): string {
  if (!isNumber(value)) return ""

  return `${value * 100} %`
}

interface BillingTableProps {
  record: CalendarEntry
}

export default function BillingTable({ record }: BillingTableProps): JSX.Element {
  // const classes = useStyles();
  const lineItems = (record && record.billing?.line_items) || []
  const total = record?.billing?.total
  const subTotal = record?.billing?.sub_total
  const totalBeforeTax = total && subTotal ? total - subTotal : undefined

  return (
    <TableContainer component={Paper}>
      <Table
        //  className={classes.table}
        size="small"
        // aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            <TableCell>Pos</TableCell>
            <TableCell align="right">Qty.</TableCell>
            <TableCell align="right">Unit</TableCell>
            <TableCell align="right">Sum</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lineItems.map((item, idx) => (
            <TableRow key={idx}>
              <TableCell component="th" scope="item">
                {item.product_code || item.title}
              </TableCell>
              <TableCell align="right">{item.quantity}</TableCell>
              <TableCell align="right">{item.unit}</TableCell>
              <TableCell align="right">{formatCent(item.amount)}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell rowSpan={3} />
            <TableCell colSpan={2}>Subtotal</TableCell>
            <TableCell align="right">{formatCent(subTotal)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Tax</TableCell>
            <TableCell align="right">{formatPercentage(record?.billing?.tax)}</TableCell>
            <TableCell align="right">{formatCent(totalBeforeTax)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2}>Total</TableCell>
            <TableCell align="right">{formatCent(total)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const useAsideStyles = makeStyles((theme) => ({
  root: {
    width: 512,
    // TODO:
    // [theme.breakpoints.down('lg')]: {
    //   display: "none",
    // },
  },
}))

interface AsideProps {
  // record?: Record
  record?: CalendarEntry
  basePath?: string
  resource?: string
}

export const CalendarEntryEditAside: FC<AsideProps> = ({ resource }) => {
  const record = useRecordContext<CalendarEntry>()
  const classes = useAsideStyles()

  if (!record) return null

  return (
    <div className={classes.root}>
      <>
        <Box m="0 0 1em 1em">
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Current Status
              </Typography>

              <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                  <Labeled source="current_status" resource={resource} label="Status">
                    <EntryStatusField source="current_status" resource={resource} record={record} />
                  </Labeled>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Labeled source="current_status_at" resource={resource} label="Changed At">
                    <DateField source="current_status_at" resource={resource} record={record} />
                  </Labeled>
                </Grid>
              </Grid>

              <Box mt="1em">
                <ConfirmButton record={record} />
                <Box component="span" mr="1em" />
                <CancelButton record={record} />
                <Box component="span" mr="1em" />
                <DeclineButton record={record} />
                <Box component="span" mr="1em" />
                <RequestButton record={record} />
              </Box>

              {/*
              <Box mt="1em" />
              <StatusQuickCreateButton />
              */}

              <Box mt="1em" />

              {/*
              <Typography variant="h6" gutterBottom>
                Billing
              </Typography>
              <BillingTable record={record} />

              <Box mt="1em" />

              <Typography variant="h6" gutterBottom>
                Quota Usage
              </Typography>
              <Typography variant="caption" gutterBottom>
                TODO
              </Typography>

              <Box mt="1em" />
              */}

              <Typography variant="h6" gutterBottom>
                Status History
              </Typography>
              {/*
              <Typography variant="caption" gutterBottom>
                TODO
              </Typography>
              */}

              <ReferenceManyField
                reference="calendar_entry_statuses"
                target="calendar_entry_id"
                // pagination={<Pagination />}
                fullWidth
              >
                <Datagrid bulkActionButtons={false}>
                  {/* <OwnerReferenceField /> */}
                  {/* <StarRatingField /> */}
                  {/* <TextField source="comment" cellClassName={classes.comment} /> */}
                  <DateField source="created_at" label="Date" showTime />
                  <TextField source="status" />
                  <UserRefField source="created_by" />
                  {/* <EditButton /> */}
                </Datagrid>
              </ReferenceManyField>

              <Box mt="1em" />

              <Typography variant="h6" gutterBottom>
                Meta
              </Typography>

              <Grid container>
                <Grid item xs>
                  <Labeled source="created_at" resource={resource}>
                    <DateField source="created_at" />
                  </Labeled>
                </Grid>
                <Grid item xs>
                  <Labeled source="updated_at" resource={resource}>
                    <DateField source="updated_at" />
                  </Labeled>
                </Grid>
                <Grid item xs>
                  <Labeled source="created_by" resource={resource}>
                    <UserRefField source="created_by" />
                  </Labeled>
                </Grid>
                <Grid item xs>
                  <Labeled source="version" resource={resource}>
                    <TextField source="version" />
                  </Labeled>
                </Grid>
              </Grid>

              <Box mt="1em" />

              <Typography variant="h6" gutterBottom>
                Billing
              </Typography>

              {!!record && <PreviewBill booking={record} />}

              <AddBillButton />

              {/*
              <ReferenceManyField
                reference="bills"
                target="booking_id"
                addLabel={false}
                // pagination={<Pagination />}
                fullWidth
              >
                <Datagrid>
                  <DateField source="created_at" label="Date" />
                  <TextField source="status" />
                  <UserRefField source="created_by" />
                </Datagrid>
              </ReferenceManyField>
              */}

              {/*
              <Box mt="1em" />
              <Typography variant="h6" gutterBottom>
                Bills
              </Typography>
              <ReferenceManyField
                reference="bills"
                target="calendar_entry_id"
                addLabel={false}
                // pagination={<Pagination />}
                fullWidth
              >
                <Datagrid rowClick="show">
                  <OwnerRefField source="customer_id" />
                  <NumberField source="vat_rate" />
                  <NumberField source="price_net" />
                  <NumberField source="price_gross" />
                  <DateField source="created_at" showTime />
                </Datagrid>
              </ReferenceManyField>
              */}

              {/*
              <Box display="flex">
                <Box flexGrow={1}>
                  <Box display="flex" mb="1em">
                    <Box mr="1em">
                      <AccessTimeIcon fontSize="small" color="disabled" />
                    </Box>
                    <Box flexGrow={1}>
                      <Typography>Current Status</Typography>
                      <TextField record={record} source="current_status" />
                    </Box>
                  </Box>
                </Box>
                <Box flexGrow={1}>
                  <Box display="flex" mb="1em">
                    <Box mr="1em">
                      <AccessTimeIcon fontSize="small" color="disabled" />
                    </Box>
                    <Box flexGrow={1}>
                      <Typography>Changed At</Typography>
                      <DateField record={record} showTime source="current_status_at" />
                    </Box>
                  </Box>
                </Box>
              </Box>
              */}
            </CardContent>
          </Card>
        </Box>
      </>
    </div>
  )
}
