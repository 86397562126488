import React from "react"
import { NumberInput, SimpleFormProps, TextInput, required, TabbedForm, FormTab } from "react-admin"

import { SelectTimezoneInput } from "../../../inputs"

type Props = Partial<SimpleFormProps> & {
  isExisting: boolean
}

export const PropertyForm: React.FC<Props> = ({ isExisting = false, defaultValues, ...rest }) => {
  // const { isValid, isSubmitting } = useFormState()

  return (
    <TabbedForm defaultValues={defaultValues}>
      <FormTab label="General">
        <TextInput
          source="short_id"
          label="MYNE Short ID"
          validate={[required()]}
          helperText="Property short ID, eg. DE0025A"
        />

        <TextInput
          source="internal_name"
          fullWidth
          helperText="Internal property name, will NOT be displayed to the customer"
        />

        <TextInput
          source="name"
          fullWidth
          validate={[required()]}
          helperText="Will be displayed to the customer, eg. inside the app"
        />

        <TextInput
          multiline
          rows={3}
          source="info_text"
          helperText="Displayed to the customer, eg. inside the app"
          fullWidth
        />

        {/*
        {!isExisting && (
          <div>
            <NumberInput
              fullWidth
              source="total_shares_count"
              helperText="Initial creates this amount of shares for the property. Shares can also be added later."
              validate={[required()]}
            />
          </div>
        )}
        */}
      </FormTab>

      <FormTab label="Address">
        <TextInput
          source="address_field_1"
          label="Address Field 1"
          fullWidth
          helperText="E.g. Street + No. for Germany"
          validate={[required()]}
        />
        <TextInput source="address_field_2" label="Address Field 2" fullWidth helperText={false} />

        <TextInput
          source="address_city"
          label="City"
          fullWidth
          helperText={false}
          validate={[required()]}
        />
        <TextInput
          source="address_postal_code"
          label="Postal Code"
          fullWidth
          helperText={false}
          validate={[required()]}
        />

        <TextInput
          source="address_country"
          label="Country"
          fullWidth
          validate={[required()]}
          helperText="Full country name, eg. `Österreich`"
        />
      </FormTab>

      <FormTab label="Location">
        <NumberInput source="address_lat" helperText="Map pin. Latitude" validate={[required()]} />
        <NumberInput
          source="address_long"
          helperText="Map pin. Longitude"
          validate={[required()]}
        />
        <SelectTimezoneInput
          source="timezone"
          helperText="Timezone of the location of the property."
          validate={[required()]}
        />
      </FormTab>

      {/*
      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <CountryRefInput fullWidth source="country_id" />
        </Box>
        <Box flex={2} ml={{ xs: 0, sm: "0.5em" }}>
          <RegionRefInput fullWidth source="region_id" />
        </Box>
        <Box flex={2} ml={{ xs: 0, sm: "0.5em" }}>
          <AreaRefInput fullWidth source="area_id" />
        </Box>
      </Box>
      */}

      <FormTab label="Images">
        <TextInput fullWidth source="card_image_url" helperText="Take URL from Storyblok" />
        <TextInput fullWidth source="header_image_url" helperText="Take URL from Storyblok" />
        <TextInput fullWidth source="thumb_image_url" helperText="Take URL from Storyblok" />
      </FormTab>

      {/*
                  <div>
                    <NullableBooleanInput source="has_newsletter" resource="customers" />
                  </div>
                  */}

      {/*
                  <Box mt="1em" />

                  <Typography variant="h6" gutterBottom>
                    Quotas
                  </Typography>

                  {isExisting && (
                    <ReferenceManyField
                      // source="id"
                      resource="booking_quotas"
                      reference="booking_quotas"
                      basePath="/booking_quotas"
                      // record={formProps.record}
                      target="property_id"
                      addLabel={false}
                      sort={{ field: "created_at", order: "ASC" }}
                    >
                      <Datagrid rowClick="edit">
                        <OwnerRefField source="customer_id" />

                        <TextField source="title" />
                        <NumberField source="share_count" />

                        <DateField source="created_at" showTime />
                      </Datagrid>
                    </ReferenceManyField>
                  )}
                  */}
      {/* <AddQuotaUserButton /> */}

      {/*
          <Toolbar
            record={record}
            // undoable={true}
            invalid={!isValid}
            handleSubmit={handleSubmit}
            saving={isSubmitting}
            resource="properties"
          />
          */}
    </TabbedForm>
  )
}

// export const PropertyForm: React.FC<Props> = ({ isExisting = false, ...rest }) => {
//   return (
//     <CompactForm layoutComponents={[RaBox]}>
//       <RaBox display="flex-column" justifyContent="space-between">
//         <RaBox display="flex" justifyContent="space-between">
//           <RaBox display="flex-column" justifyContent="space-around">
//             <Typography variant="h5">General</Typography>

//             <BooleanInput source="is_published" />
//             <BooleanInput source="is_featured" />

//             <TextInput source="title" />
//             <TextInput source="description_title" />
//             <TextInput multiline rows={3} source="description" />
//             <TextInput source="location_title" />
//             <TextInput source="location_description" />
//             <TextInput source="highlights_title" />
//             <TextInput source="highlights_description" />
//           </RaBox>

//           <RaBox display="flex-column" justifyContent="space-around">
//             <Typography variant="h5">Location</Typography>
//             <TextInput source="country_code" />
//             <TextInput source="region_code" />
//             <TextInput source="area_code" />
//           </RaBox>

//           <RaBox display="flex-column" justifyContent="space-around">
//             <Typography variant="h5">Address</Typography>
//             <TextInput source="address_field_1" />
//             <TextInput source="address_field_2" />
//             <TextInput source="address_city" />
//             <TextInput source="address_postal_code" />
//             <TextInput source="address_country" />
//             <NumberInput source="address_lat" />
//             <NumberInput source="address_long" />
//           </RaBox>
//         </RaBox>
//       </RaBox>

//       <RaBox display="flex" justifyContent="space-around">
//         <RaBox display="flex-column" justifyContent="space-around">
//           <Typography variant="h5">Attributes</Typography>

//           <SelectPropertyTypeInput source="property_type" />
//           <SelectPropertyStyleInput source="property_style" />
//           <SelectPropertyVicinitiesInput source="property_vicinities" />
//           <SelectPropertyActivityInput source="activities" />
//           <SelectPropertyAttributesOtherInput source="tags" />
//           <TextInput source="property_attribute_tags" />

//           <TextInput source="construction_year" />
//           <TextInput source="living_area_sqm" />
//           <TextInput source="plot_area_sqm" />
//           <TextInput source="rooms_count" />
//           <TextInput source="bedrooms_count" />
//           <TextInput source="bathrooms_count" />
//         </RaBox>

//         <RaBox display="flex-column" justifyContent="space-around">
//           <Typography variant="h5">Invest</Typography>
//           <TextInput source="total_shares_count" />
//           <TextInput source="salesprice_full" />
//           <TextInput source="salesprice_share" />
//           <TextInput source="salesprice_currency" />
//         </RaBox>
//       </RaBox>

//       {isExisting ? <TextInput source="id" disabled /> : null}
//     </CompactForm>

/*
    <TabbedForm {...rest} redirect={isExisting ? "show" : "list"}>
      <FormTab label="General">
        {isExisting ? <TextInput source="id" disabled /> : null}

        <BooleanInput source="is_published" />
        <BooleanInput source="is_featured" />

        <TextInput source="title" />
        <TextInput source="description_title" />
        <TextInput multiline rows={3} source="description" />
        <TextInput source="location_title" />
        <TextInput source="location_description" />
        <TextInput source="highlights_title" />
        <TextInput source="highlights_description" />
      </FormTab>

      <FormTab label="Location">
        <TextInput source="country_code" />
        <TextInput source="region_code" />
        <TextInput source="area_code" />

        <TextInput source="address_field_1" />
        <TextInput source="address_field_2" />
        <TextInput source="address_city" />
        <TextInput source="address_postal_code" />
        <TextInput source="address_country" />
        <NumberInput source="address_lat" />
        <NumberInput source="address_long" />
      </FormTab>

      <FormTab label="Attributes">
        <SelectPropertyTypeInput source="property_type" />
        <SelectPropertyStyleInput source="property_style" />
        <SelectPropertyVicinitiesInput source="property_vicinities" />
        <SelectPropertyActivityInput source="activities" />
        <SelectPropertyAttributesOtherInput source="tags" />
        <TextInput source="property_attribute_tags" />

        <TextInput source="construction_year" />
        <TextInput source="living_area_sqm" />
        <TextInput source="plot_area_sqm" />
        <TextInput source="rooms_count" />
        <TextInput source="bedrooms_count" />
        <TextInput source="bathrooms_count" />
      </FormTab>

      <FormTab label="Invest">
        <TextInput source="total_shares_count" />
        <TextInput source="salesprice_full" />
        <TextInput source="salesprice_share" />
        <TextInput source="salesprice_currency" />
      </FormTab>
    </TabbedForm>
    */
//   )
// }
