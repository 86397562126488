import React from "react"
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  EditProps,
  List,
  ListProps,
  NumberField,
  ReferenceManyField,
  Show,
  ShowButton,
  ShowProps,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  required,
} from "react-admin"

import { SelectTimezoneInput } from "../../../inputs"
import { UserRefField } from "../../iam/users"
import { OwnerRefField } from "../../real_estate/owners"
import { PropertyRefField, PropertyRefInput } from "../../real_estate/properties"
import EntryStatusField from "../calendar_entries/fields/EntryStatusField"
import { CreateMemberButton } from "../calendar_members/CreateMemberButton"
import { QuotaRefField } from "../quotas"
import { CalendarReferenceManyField } from "./CalendarReferenceManyField"
import { CalendarTitle } from "./CalendarTitle"
import { CreateEventButton } from "./CreateEventButton"

export const CalendarList = () => (
  <List exporter={false}>
    <Datagrid bulkActionButtons={false} rowClick="show">
      <PropertyRefField source="property_id" />
      <TextField source="resource_timezone" />
      <DateField source="created_at" showTime />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
)

export const CalendarShow = () => (
  <Show title={<CalendarTitle />}>
    <TabbedShowLayout>
      <Tab label="Calendar">
        <CalendarReferenceManyField label={false} />
      </Tab>

      {/*
      <Tab label="Events" path="entries">
        <CreateEventButton />
        <ReferenceManyField
          reference="calendar_entries"
          target="calendar_id"
          sort={{ field: "created_at", order: "DESC" }}
          label={false}
        >
          <Datagrid bulkActionButtons={false} rowClick="edit">
            <TextField source="type" />
            <OwnerRefField source="customer_id" />
            <UserRefField source="user_id" />
            <TextField source="title" />
            <DateField source="start_date" />
            <DateField source="end_date" />
            <EntryStatusField source="current_status" />
            <DateField source="created_at" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      */}

      <Tab label="Access" path="members">
        <CreateMemberButton />
        <ReferenceManyField
          reference="calendar_members"
          target="calendar_id"
          // sort={{ field: "created_at", order: "DESC" }}
          label={false}
        >
          <Datagrid bulkActionButtons={false}>
            <UserRefField source="user_id" />
            <OwnerRefField source="customer_id" />
            <QuotaRefField source="default_quota_id" label="Default Quota" />
            <DateField source="created_at" />
            <ShowButton />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>

      <Tab label="Calendar Details">
        <PropertyRefField source="property_id" />
        <TextField source="title" />
        <TextField source="id" />
        <DateField source="created_at" showTime />
        <DateField source="updated_at" showTime />
        <TextField source="created_by" />
        <NumberField source="version" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export const CalendarCreate = () => (
  <Create redirect="show">
    <SimpleForm>
      <TextInput source="title" validate={[required()]} />
      <PropertyRefInput source="property_id" />
      <SelectTimezoneInput source="resource_timezone" />
    </SimpleForm>
  </Create>
)

export const CalendarEdit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm>
      {/* <TextInput source="id" /> */}

      <PropertyRefInput source="property_id" />
      <TextInput source="title" />
      <SelectTimezoneInput source="resource_timezone" />
    </SimpleForm>
  </Edit>
)
