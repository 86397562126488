import { detailedDiff, updatedDiff } from "deep-object-diff"
import { pick } from "lodash"

interface IDiffDetails {
  added: Record<string, undefined> | {}
  deleted: Record<string, undefined> | {}
  updated: Record<string, undefined> | {}
}

const getKeysTypedAsArrayOrObject = (object: Record<string, unknown>) => {
  return Object.keys(object).filter(
    (key) => Array.isArray(object[key]) || typeof object[key] === "object",
  )
}

export const countDiff = (
  newElements: Record<string, unknown>,
  prevElements: Record<string, unknown>,
) => {
  Object.keys(prevElements).forEach((key) => {
    if (prevElements[key] === null) {
      prevElements[key] = ""
    }
  })

  const newKeysAsArrayOrObject = getKeysTypedAsArrayOrObject(newElements)

  const detailed = detailedDiff(prevElements, newElements) as IDiffDetails

  const newDataTypedAsArrayOrObject = pick(newElements, newKeysAsArrayOrObject)

  const returnedData = { ...detailed.added, ...detailed.updated, ...newDataTypedAsArrayOrObject }

  return returnedData
}
