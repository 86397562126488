import { InputProps, SelectInput } from "react-admin"

export const choices = [
  { id: "night", name: "Night" },
  { id: "guest", name: "Guest" },
  { id: "pet", name: "Pet" },
  { id: "local_tourism_tax_total_count", name: "Local Tourism Tax Total Count" },
]

export const UnitSelectInput: React.FC<InputProps> = (props) => (
  <SelectInput label="Unit" {...props} choices={choices} />
)
