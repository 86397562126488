import {
  EditButton,
  RedirectionSideEffect,
  ShowButton,
  TopToolbar,
  useShowContext,
} from "react-admin"
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  List,
  ReferenceManyField,
  Show,
  TabbedShowLayout,
  NumberField,
  Tab,
  TextField,
} from "react-admin"
import { UserRefField } from "resources/iam/users"
import { PropertyRefField } from "resources/real_estate/properties"

import { OwnerRefField, OwnerRefInput } from "../../real_estate/owners"
import { CalendarEntryRefField, CalendarEntryRefInput } from "../../scheduling/calendar_entries"
import { BillStatusRefField } from "../bill_statuses"
import { AddBillPaymentStatusButton } from "./AddBillPaymentStatusButton"
import { AddBillStatusButton } from "./AddBillStatusButton"
import { AddGroupButton } from "./AddGroupButton"
import { BillCreateForm } from "./BillCreateForm"
import { BillEditForm } from "./BillEditForm"
import { BillPdfField } from "./BillPdfField"
import { BillTitle } from "./BillTitle"
import BookingBillField from "./BookingBillField"
import { MoneyField } from "./MoneyField"
import ApproveButton from "./buttons/ApproveButton"
import CancelButton from "./buttons/CancelButton"

const billFilters = [
  <CalendarEntryRefInput source="booking_id" alwaysOn />,
  <OwnerRefInput source="customer_id" alwaysOn />,
]

export const BillList = () => (
  <List filters={billFilters} exporter={false} sort={{ field: "created_at", order: "DESC" }}>
    <Datagrid bulkActionButtons={false} rowClick="show">
      <CalendarEntryRefField source="booking_id" />
      <OwnerRefField source="customer_id" />
      <TextField source="unique_number" />
      <TextField source="current_status" />
      <DateField source="check_in_day" />
      <DateField source="check_out_day" />
      <MoneyField source="subtotal" />
      <MoneyField source="vat" />
      <MoneyField source="total" />
      <MoneyField source="due" />
      <DateField source="created_at" showTime />
      <ShowButton />
    </Datagrid>
  </List>
)

const BillShowActions = () => {
  const { record } = useShowContext()

  return (
    <TopToolbar>
      {/* <Button
        color="primary"
        label="Reschedule"
        startIcon={<RescheduleIcon />}
        onClick={() => {
          alert("Not Implemented")
        }}
      /> */}

      <ApproveButton record={record} />
      <CancelButton record={record} />

      <EditButton />
    </TopToolbar>
  )
}

export const BillShow = () => (
  <Show
    // actions={<BillShowActions />}
    title={<BillTitle />}
  >
    <TabbedShowLayout spacing={2}>
      <Tab label="Bill Details">
        <CalendarEntryRefField source="booking_id" />
        <OwnerRefField source="customer_id" />
        <PropertyRefField source="property_id" />

        <TextField source="current_status" label="Current Bill Status" />
        <TextField source="current_payment_status" label="Current Payment Status" />

        <DateField source="check_in_day" />
        <DateField source="check_out_day" />

        <TextField source="internal_notes" />

        <TextField source="timezone" />

        <BookingBillField />
      </Tab>

      {/* TODO: Enable when ready
      <Tab label="Statuses" path="statuses">
        <AddBillStatusButton />
        <ReferenceManyField
          reference="bill_statuses"
          target="bill_id"
          sort={{ field: "created_at", order: "DESC" }}
          label="Bill Statuses"
        >
          <Datagrid bulkActionButtons={false}>
            <TextField source="status" />
            <TextField source="internal_notes" />
            <UserRefField source="created_by" />
            <DateField source="created_at" showTime />
          </Datagrid>
        </ReferenceManyField>

        <AddBillPaymentStatusButton />
        <ReferenceManyField
          reference="bill_payment_statuses"
          target="bill_id"
          sort={{ field: "created_at", order: "DESC" }}
          label="Payment Statuses"
        >
          <Datagrid bulkActionButtons={false}>
            <TextField source="status" />
            <TextField source="internal_notes" />
            <UserRefField source="created_by" />
            <DateField source="created_at" showTime />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      */}

      <Tab label="Payment">
        <TextField source="current_payment_status" />
        <TextField source="collection_method" />
        <TextField source="payed_amount" />
        <TextField source="payed_amount_updated_at" />
        <TextField source="payed_amount_updated_by" />
      </Tab>

      <Tab label="Groups" path="groups">
        <AddGroupButton />
        <ReferenceManyField
          reference="bill_groups"
          target="bill_id"
          sort={{ field: "created_at", order: "DESC" }}
          label={false}
        >
          <Datagrid bulkActionButtons={false} rowClick="show">
            <TextField source="title" />
            <TextField source="category_code" />
            <TextField source="vat_rate" />
            <MoneyField source="subtotal" />
            <MoneyField source="vat" />
            <MoneyField source="total" />
            <MoneyField source="due" />
            <TextField source="billable_amount_from" />
            <DateField source="created_at" showTime />
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>

      <Tab label="Document Content">
        <TextField source="document_type" />
        <TextField source="unique_number" />
        <TextField source="language_code" />
        <DateField source="billing_date" />
        <DateField source="supply_date" />
        <DateField source="delivery_date" />
        <DateField source="period_start" />
        <DateField source="period_end" />
        <DateField source="due_date" />
        <NumberField source="due_days" />
        <TextField source="title_text" />
        <TextField source="intro_text" />
        <TextField source="footer_text" />
        <TextField source="closing_text" />
        <TextField source="description_text" />
        <TextField source="property_label" />
      </Tab>

      <Tab label="PDF" path="pdf">
        <BillPdfField />
      </Tab>

      <Tab label="Issuer">
        <TextField source="issuer_name" />
        <TextField source="issuer_address1" />
        <TextField source="issuer_address2" />
        <TextField source="issuer_address_city" />
        <TextField source="issuer_address_postal_code" />
        <TextField source="issuer_address_country" />
        <TextField source="issuer_email" />
        <TextField source="issuer_phone" />
        <TextField source="issuer_tax_exempt" />
        <TextField source="issuer_tax_id" />
        <TextField source="issuer_vat_id" />
        <TextField source="issuer_commercial_register_number" />
        <TextField source="issuer_local_court" />

        <TextField source="bank_name" />
        <TextField source="bank_account_owner" />
        <TextField source="bank_iban" />
        <TextField source="bank_bic" />
      </Tab>

      <Tab label="Recipient">
        <TextField source="recipient_name" />
        <TextField source="recipient_address1" />
        <TextField source="recipient_address2" />
        <TextField source="recipient_address_city" />
        <TextField source="recipient_address_postal_code" />
        <TextField source="recipient_address_country" />
        <TextField source="recipient_email" />
        <TextField source="recipient_phone" />
        <TextField source="recipient_tax_exempt" />
        <TextField source="recipient_tax_id" />
        <TextField source="recipient_vat_id" />
        <TextField source="recipient_commercial_register_number" />
        <TextField source="recipient_local_court" />
      </Tab>

      <Tab label="Meta">
        <TextField source="id" />
        <DateField showTime source="created_at" />
        <DateField showTime source="updated_at" />
        <TextField source="created_by" />
        <NumberField source="version" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

const redirect: RedirectionSideEffect = (resource, id, data) =>
  data ? (data.booking_id ? `calendar_entries/${data.booking_id}/show/billing` : "edit") : "show"

export const BillCreate = () => (
  <Create redirect={redirect}>
    <BillCreateForm
      isExisting={false}
      // defaultValues={{
      //   due_days: 14,
      //   title_text: "Rechnung",
      //   intro_text:
      //     "Ihr Aufenthalt vom {{ bill.period_start | date: '%D.%m.%Y' }} bis zum {{ bill.period_end | date: '%D.%m.%Y' }} in der {{ property.address_field_1 }}, {{ property.address_postal_code }} {{ property.address_city }}, {{ property.address_country }}",
      //   closing_text:
      //     "Bitte überweisen Sie innerhalb von {{ bill.due_days }} Tagen den zahlbaren Betrag mit dem Verwendungszweck {{ bill.unique_number }} auf das unten genannte Konto.",
      // }}
    />
  </Create>
)

export const BillEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show" title={<BillTitle />}>
    <BillEditForm isExisting />
  </Edit>
)
