import { Alert, Button, LinearProgress, Stack } from "@mui/material"
import { PDFViewer } from "@react-pdf/renderer"
import React from "react"
import { useGetOne } from "react-admin"

import { BillDto } from "@/types/Bill"

import { BookingBill } from "../../../../components/BookingBill"
import { BookingBillPdfDoc } from "../../../../components/BookingBillPdfDoc"
// import { fetchPreviewBill } from "@/services/api/billing.api"
import { fetchPreviewBill } from "../../../../services/api/billing.api"
import { CalendarEntry } from "../../types"

interface BillProps {
  booking: CalendarEntry
  // propertyId: string
  autoLoad?: boolean
}

export const PreviewBill = ({ booking, autoLoad = false }: BillProps) => {
  const [loading, setLoading] = React.useState(false)
  const [hasError, setHasError] = React.useState(false)
  const [bill, setBill] = React.useState<BillDto | undefined>(undefined)
  const {
    data: calendar,
    isLoading: loadingCalendar,
    error: errorCalendar,
  } = useGetOne("calendars", { id: booking.calendar_id })

  const fetch = React.useCallback(async () => {
    if (!calendar) return

    try {
      setLoading(true)
      setHasError(false)

      const data = await fetchPreviewBill(
        calendar.property_id,
        booking.customer_id.toString(),
        booking.start_date,
        booking.end_date,
        booking.adults_count,
        booking.children_count,
        booking.infants_count,
        booking.has_pets ?? false,
        booking.local_tourism_tax_total_count ?? 0,
      )

      setBill(data)
    } catch (error) {
      console.log("[fetch] error", error)

      setHasError(true)
    } finally {
      setLoading(false)
    }
  }, [calendar])

  // if (!bill) return

  React.useEffect(() => {
    if (autoLoad) fetch()
  }, [autoLoad])

  return (
    <Stack spacing={2}>
      {loading && <LinearProgress />}
      {/* {bill && <BookingBill bill={bill} />} */}

      {bill && (
        <PDFViewer showToolbar width={1024} height={1200}>
          <BookingBillPdfDoc bill={bill} />
        </PDFViewer>
      )}

      {hasError && <Alert severity="error">Error creating preview bill</Alert>}

      <Button variant="outlined" onClick={fetch} disabled={loadingCalendar}>
        {autoLoad ? "Reload Preview Bill" : "Generate Preview Bill"}
      </Button>
    </Stack>
  )
}
