import { get } from "lodash"
import { parse } from "query-string"
import { useEditController } from "react-admin"
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  Filter,
  List,
  ListProps,
  ShowGuesser,
  TextField,
} from "react-admin"

import { CalendarEntryRefField } from "../calendar_entries"
import { EntryStatusForm } from "./EntryStatusForm"

const EntryStatusFilter = (props: any) => {
  return (
    <Filter {...props}>
      <CalendarEntryRefField />
    </Filter>
  )
}

export const EntryStatusList = (props: ListProps) => (
  <List {...props} filters={<EntryStatusFilter />}>
    <Datagrid rowClick="show">
      <CalendarEntryRefField source="calendar_entry_id" />

      <TextField source="status" />
      <TextField source="internal_notes" />

      <DateField source="created_at" showTime />
    </Datagrid>
  </List>
)

export const EntryStatusShow = ShowGuesser
// export const EntryStatusShow = (props: ShowProps) => (
//   <Show {...props}>
//     <SimpleShowLayout>
//       <TextField source="id" />
//       <CalendarRefField />
//     </SimpleShowLayout>
//   </Show>
// )

export const EntryStatusCreate = (props: any) => {
  const { calendar_entry_id } = parse(props.location.search)
  const redirect = calendar_entry_id
    ? `/calendar_entries/${calendar_entry_id}/show/statuses`
    : "show"

  return (
    <Create {...props} mutationMode="pessimistic" redirect={redirect}>
      <EntryStatusForm isExisting={false} defaultValues={{ calendar_entry_id }} />
    </Create>
  )
}

export const EntryStatusEdit = (props: any) => {
  const controllerProps = useEditController<any>(props)
  const record = controllerProps.record
  const calendar_entry_id = get(record, "calendar_entry_id")
  const redirect = calendar_entry_id
    ? `/calendar_entries/${calendar_entry_id}/show/statuses`
    : "show"

  return (
    <Edit {...props} mutationMode="pessimistic" redirect={redirect}>
      <EntryStatusForm isExisting />
    </Edit>
  )
}
