import { EUR } from "@dinero.js/currencies"
import { dinero, isZero, toFormat } from "dinero.js"
import { Liquid } from "liquidjs"

export const liquidEngine = new Liquid()

export const transformer = ({ amount, currency }: any) =>
  Intl.NumberFormat("de-DE", { style: "currency", currency: currency.code }).format(amount)

export const getFormattedAmount = (
  amountInEuroCents: number | undefined,
  scale?: number,
): string | undefined => {
  if (!amountInEuroCents) return

  const monetary = dinero({ amount: amountInEuroCents, currency: EUR, scale })

  if (isZero(monetary)) return

  return toFormat(monetary, transformer)
}

export const formatDate = (v: string | undefined) =>
  !!v ? Intl.DateTimeFormat("de-DE").format(new Date(v)) : "N/A"
