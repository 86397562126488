import { LockReset } from "@mui/icons-material"
import { Alert } from "@mui/material"
import {
  Datagrid,
  List,
  Show,
  Create,
  Edit,
  Filter,
  TextField,
  TextInput,
  EmailField,
  DateField,
  NumberField,
  TabbedShowLayout,
  Tab,
  BooleanField,
  Pagination,
  PaginationProps,
  ReferenceManyField,
  ShowButton,
  EditButton,
  TopToolbar,
  Button,
} from "react-admin"
import { OwnerRefField } from "resources/real_estate/owners"
import { CalendarRefField } from "resources/scheduling/calendars"

import { TextArrayField } from "../../../fields"
import { UserEditAside } from "./UserEditAside"
import { UserForm } from "./UserForm"
import { UserTitle } from "./UserTitle"

const UserFilter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="title" alwaysOn />
    </Filter>
  )
}

const PostPagination = (props: PaginationProps) => (
  <Pagination rowsPerPageOptions={[10, 25, 50]} {...props} />
)

export const UserList = () => (
  <List
    // filters={<UserFilter />}
    exporter={false}
    sort={{ field: "last_name", order: "ASC" }}
    pagination={<PostPagination />}
  >
    <Datagrid bulkActionButtons={false} rowClick="show">
      <TextField source="email" />
      <TextField source="first_name" />
      <TextField source="last_name" />
      <TextArrayField source="roles" />
      <TextField source="account_id" label="IPID" />
      <BooleanField source="is_disabled" />
      <DateField source="created_at" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
)

const UserShowActions = () => (
  <TopToolbar>
    <Button color="primary" label="Reset Password" startIcon={<LockReset />} onClick={() => {}} />
    <EditButton />
  </TopToolbar>
)

export const UserShow = () => (
  <Show title={<UserTitle />} actions={<UserShowActions />}>
    <TabbedShowLayout>
      <Tab label="User Details">
        <TextField source="first_name" />
        <TextField source="last_name" />
        <TextField source="salutation" />
        <EmailField source="email" />
        <TextField source="phone" />
        <TextArrayField source="roles" />
        <TextField source="language" />
        <TextField source="timezone" />
        <BooleanField source="is_disabled" />
      </Tab>

      <Tab label="Owner Memberships">
        <ReferenceManyField
          reference="customer_memberships"
          target="user_id"
          fullWidth
          label={false}
        >
          <Datagrid
            bulkActionButtons={false}
            empty={<Alert severity="info">This user is not a member of any owner.</Alert>}
          >
            <OwnerRefField />
            <TextField source="role" />
            <BooleanField source="is_disabled" />
            <DateField source="created_at" label="Created" />
            <ShowButton />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>

      <Tab label="Calendar Accesses">
        <ReferenceManyField reference="calendar_members" target="user_id" fullWidth label={false}>
          <Datagrid
            bulkActionButtons={false}
            empty={
              <Alert severity="info">This user is does not have access to any calendar.</Alert>
            }
          >
            <CalendarRefField />
            <OwnerRefField />
            <DateField source="created_at" />
            <ShowButton />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>

      <Tab label="Meta">
        <TextField source="id" />
        <TextField source="account_id" label="Account ID" />
        <DateField source="created_at" showTime />
        <DateField source="updated_at" showTime />
        <TextField source="created_by" />
        <NumberField source="version" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export const UserCreate = () => {
  const defaultValues = {
    language: "de",
    timezone: "Europe/Berlin",
    create_account: true,
  }

  return (
    <Create>
      <UserForm isExisting={false} defaultValues={defaultValues} />
    </Create>
  )
}

export const UserEdit = () => (
  <Edit aside={<UserEditAside />} mutationMode="pessimistic">
    <UserForm isExisting />
  </Edit>
)
