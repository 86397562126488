import React from "react"
import { SimpleForm, SimpleFormProps, TextInput } from "react-admin"
import { CalendarEntryRefInput } from "../calendar_entries"
import { SelectEntryStatusInput } from "./inputs/SelectEntryStatusInput"

type Props = Partial<SimpleFormProps> & {
  isExisting: boolean
}

export const EntryStatusForm: React.FC<Props> = ({ isExisting = false, ...rest }) => (
  <SimpleForm {...rest}>
    <CalendarEntryRefInput source="calendar_entry_id" />

    {/* <TextInput source="status" /> */}
    <SelectEntryStatusInput source="status" />

    <TextInput source="internal_notes" multiline rows={3} />
  </SimpleForm>
)
